import {extendObservable} from 'mobx';
import moment from "moment-timezone";
const DEFAULT_TIMEZONE = 'America/New_York';
class Store {
    constructor() {
        extendObservable(this, {
            loader: false,
            open: false,
            message: '',
            selectedDate: '',
            showTimeSlots: false,
            showUserForm: false,
            showSuccessResult: false,
            slotTime: '',
            timezone: 'America/New_York',
            timezoneLabel: '(GMT-05:00) Eastern Time',
            timezoneAvailableTimeSlots: {},
            availableTimeSlots: {},
            reservedTimeSlots:  [
                // {
                //     "start": "Tue Dec 17 2019 16:00:00 +00:00",
                //     "end": "Tue Dec 17 2019 16:45:00 +00:00"
                // }
            ],
            googleReservedTimeSlots: [],
            googleTimeSlots: [],
            googleTimezones: {},
            timeSlots: [],
            enable: [],
            month: moment.tz(DEFAULT_TIMEZONE).format('M'),
            year: moment.tz(DEFAULT_TIMEZONE).format('YYYY'),
            minDate: moment.tz(DEFAULT_TIMEZONE).format('YYYY-MM-DD'),
            calendars: [],
            duration: 60,
            holdId: null,
            variant: 'error'
        })
    }
}

export default new Store();
