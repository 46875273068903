import React, { Component } from 'react';
import { Grid, Divider } from '@material-ui/core';
import {
    DateInput,
} from 'semantic-ui-calendar-react';
// import moment from 'moment';
import axios from 'axios';
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE, DEFAULTTIMESLOTS, PORTALAPIS } from '../../constants';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx'
import { Segment, Radio } from 'semantic-ui-react';
import {  Form  } from 'semantic-ui-react';
import UserDetailsForm from '../../user-details/google-user-detail-form';

// export default class CalendarGrid extends Component {
export default inject('appStore')(observer(class CalendarGrid extends Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()

        this.state = {
            calendarId: props.calendarId,
            companyId: props.companyId,
            askAQuesionSelected: false,
        }
    }

    getCalendarList = async () => {
        return axios({
            method: 'post',
            url: PORTALAPIS.CALENDARS,
            headers: {
            },
            data: {
                companyId: this.props.companyId,
            },
            // withCredentials: false,
        })
    }

    getTimeSlots = () => {

        const { companyId, calendarId } = this.state
        const { conflictingCalendars } = this.props
        return axios({
            method: 'post',
            url: PORTALAPIS.SLOTS,
            headers: {
            },
            data: {
                companyId,
                calendarId: !conflictingCalendars ? calendarId : undefined,
            },
            // withCredentials: false,
        })
    }

    getReservedTimeSlots = () => {

        const { companyId, calendarId } = this.state
        const { conflictingCalendars } = this.props;

        // Get all reservations
        return axios.post(PORTALAPIS.RESERVED, { companyId, calendarId: conflictingCalendars ? undefined : calendarId })
    }

    componentDidMount() {
        const { calendarId } = this.state;
        const  {  calendarsToShow  }  = this.props;

        if (this.props.appStore.calendars.length === 0) {
            this.props.toggleLoader();
            axios.all([this.getCalendarList()])
                .then(([res]) => {

                    if (res && res.status === 200) {
                        const calendars = res.data;

                        const filterCalendars = calendars.filter(calendar => calendar.accessRole === 'owner' && (!calendarsToShow  ||  calendarsToShow.includes(calendar.summary)))

                        this.props.appStore.calendars = filterCalendars;

                        if (filterCalendars && filterCalendars.length === 1) {
                            // No calendar
                            const calendarId = (calendars.find(cal => cal.primary)?.id) || 'primary'
                            return this.onCalendarSelect(calendarId);
                        }

                        if (calendarId) {
                            return this.onCalendarSelect(calendarId);
                        }

                    } else {
                        // NO CALENDARS???
                        console.log('No calendars exist');
                        this.onCalendarSelect()
                    }
                })
                .catch(err => {
                    // Lets consider it as primary
                    this.onCalendarSelect()
                })
                .finally(() => {
                    this.props.toggleLoader();
                })

        } else {
            if (calendarId) {
                return this.onCalendarSelect(calendarId);
            }
        }
    }

    onCalendarSelect = (calendarId = 'pimary') => {

        const self = this;
        const { calendars } = this.props.appStore;
        this.setState({
            calendarId
        }, () => {

            const interval = (calendars.find(cal => (calendarId === 'primary' && cal.primary) || cal.id === calendarId)?.metadata?.event_duration) || 60
            this.props.updateCalendarId && this.props.updateCalendarId(calendarId);
            this.props.updateInterval && this.props.updateInterval(interval);

            this.props.appStore.duration = interval

            if (Object.keys(this.props.appStore.googleTimeSlots).length === 0 && Object.keys(this.props.appStore.googleReservedTimeSlots).length === 0) {
                this.props.toggleLoader();
                axios.all([this.getTimeSlots(), this.getReservedTimeSlots()])
                    .then(axios.spread(function (responseTimeSlots, responseReserve) {
                        // Both requests are now complete
                        self.props.toggleLoader();
                        const timeSlots = responseTimeSlots.data.workingHours;
                        const reservedTimeSlots = responseReserve.data.appointments.calendarEvents;
                        const timezones = responseReserve.data.appointments.timezones;
                        self.props.appStore.googleTimeSlots = timeSlots;
                        self.props.appStore.googleReservedTimeSlots = reservedTimeSlots;
                        self.props.appStore.googleTimezones = timezones;

                        self.CalculateFreeTimeSlots();

                    }))
                    .catch(err => {
                        // DEFAULT FALLBACK
                        this.props.appStore.timeSlots = DEFAULTTIMESLOTS;
                        self.CalculateFreeTimeSlots();
                        console.log(err);
                        self.props.toggleLoader();
                    });
            } else {
                self.CalculateFreeTimeSlots();
            }
            
            this.activateCalendarHeaders();

        })

    }

    componentDidUpdate() {
        if (this.myRef && this.myRef.current) {
            window.scrollTo(0, this.myRef.current.offsetTop);

        }
    }

    handleMonthChange(currentString, isForward) {
        let [month, year] = currentString.split(" ");
        let monthNumber = +moment().month(month).format('M');

        year = Number(year);
        monthNumber = Number(monthNumber);

        if (isForward) {
            monthNumber += 1;
        } else {
            monthNumber -= 1;
        }

        if (monthNumber === 13) {
            monthNumber = 1;
            year += 1
        } else if (monthNumber === 0) {
            monthNumber = 12;
            year -= 1;
        }

        if (monthNumber < +moment().format('M') && year === +moment().format('YYYY')) {
            return;
        }

        this.props.appStore.month = monthNumber;
        this.props.appStore.year = year;
        this.CalculateFreeTimeSlots();
    }

    getWorkingHoursOfTheDay(date, scheduleIntervals, calendarId) {

        try {

            const workingHours = [];

            let dayOfWeek = moment(date).day()
            dayOfWeek += 1;

            let calendarDate = moment(date).format('YYYY-MM-DD')

            scheduleIntervals.sort((a, b) => {
                return b.end_time === a.start_time ? 1 : -1
            })

            for (const scheduleInterval of scheduleIntervals) {

                if (calendarId !== scheduleInterval.calendarId) continue
                let start = null;
                let end = null;

                const validDays = [];

                const startDay = scheduleInterval.start_day;
                const endDay = scheduleInterval.end_day;

                if (Number(startDay) !== 0 && Number(startDay) !== 8) {
                    let validDay = startDay
                    validDays.push(validDay)

                    while (Number(validDay) !== Number(endDay)) {
                        validDay += 1;

                        if (validDay > 7) {
                            validDay = 1
                        }

                        validDays.push(validDay)
                    }
                }


                if (
                    (Number(scheduleInterval.start_day) === 0) ||
                    (Number(scheduleInterval.start_day) === 8 && Number(dayOfWeek) >= 2 && dayOfWeek <= 6) ||
                    (validDays.includes(dayOfWeek)
                    )
                ) {
                    // Every Day OR WEEK DAYS OR SINGLE DAYS
                    start = moment.tz(calendarDate + ' ' + scheduleInterval.start_time, DEFAULT_TIMEZONE).toDate()
                    end = moment.tz(calendarDate + ' ' + scheduleInterval.end_time, DEFAULT_TIMEZONE).toDate()

                    // if (start)
                    let nextDay = false
                    if (moment(end).isBefore(start)) {
                        // If end is before start then it must have gone to the next day
                        end = moment(end).add(1, 'days').toDate();
                        calendarDate = moment(end).format('YYYY-MM-DD')
                        nextDay = true
                    }

                    workingHours.push({
                        // start: setUserTimeZoneOnDate(setTimeZoneonDate(start, 'America/New_York')),
                        // end: setUserTimeZoneOnDate(setTimeZoneonDate(end, 'America/New_York')),
                        ...scheduleInterval,
                        start,
                        end,
                        nextDay,

                    })
                }
            }

            workingHours.sort((a, b) => {
                return moment(a.start).isAfter(b.start) ? 1 : -1
            });

            // return workingHours

            const continueWorkingHours = [];

            for (let i = 0; i < workingHours.length; i++) {
                const currentTime = workingHours[i];

                if (workingHours[i + 1]) {
                    for (let j = i + 1; j < workingHours.length; j++) {
                        const nextTime = workingHours[j]

                        if (moment(nextTime.start).isSameOrBefore(currentTime.end)) {
                            i++;

                            if (moment(nextTime.end).isAfter(currentTime.end)) {
                                currentTime.end = nextTime.end
                            }

                        } else {
                            break;
                        }
                    }
                }

                continueWorkingHours.push(currentTime)
            }

            return continueWorkingHours
        } catch (e) {
            return []
        }
    }

    // Call this method when calendar month and year changes.
    CalculateFreeTimeSlots() {


        // This means we need to check slot availablity in call calendars
        const { conflictingCalendars } = this.props
        const { month, year } = this.props.appStore;

        var month_year = month + '_' + year;

        const { availableTimeSlots, duration } = this.props.appStore;

        if (toJS(availableTimeSlots)[month_year]) return
        availableTimeSlots[month_year] = {};

        // if (!toJS(availableTimeSlots)[month_year]) {
        //     availableTimeSlots[month_year] = {};
        // } else {
        //     return this.updateCalendar();
        // }

        this.props.toggleLoader();

        setTimeout(() => {

            const { googleTimeSlots, googleReservedTimeSlots, googleTimezones } = this.props.appStore;
            const currentMonth = moment.tz(DEFAULT_TIMEZONE).format('M');
            // GET start and end date of month
            let startDate, endDate;
            if (month === currentMonth) {
                startDate = moment.tz([], DEFAULT_TIMEZONE); // Start from today
            } else {
                startDate = moment.tz([year, month - 1], DEFAULT_TIMEZONE);
            }

            endDate = moment.tz(startDate, DEFAULT_TIMEZONE).endOf('month');
            for (let day = startDate; day.isSameOrBefore(endDate); day = day.add(1, 'day')) {
                // Time slots available for day of the week

                // FOR EACH CALENDAR WE NEED TO CALCULATE THEM AND ADD THEM IN FREE SLOTS.

                const freeTimeSlots = [];
                for (const calendarId of Object.keys(googleTimeSlots)) {

                    if (conflictingCalendars && calendarId !== this.state.calendarId) continue

                    const timeSlots = googleTimeSlots[calendarId]

                    // const timeFramesOfTheDay = timeSlots.filter(frame =>
                    //     (frame.day.toLowerCase() === day.format('dddd').toLowerCase() &&
                    //         (!frame.date || (moment.tz(day.format('YYYY-MM-DD'), DEFAULT_TIMEZONE)
                    //                 .isSame(moment.tz(frame.date, DEFAULT_TIMEZONE))
                    //         ))));

                    const timezone = googleTimezones[calendarId] || DEFAULT_TIMEZONE

                    const timeFramesOfTheDay = this.getWorkingHoursOfTheDay(day, toJS(timeSlots), calendarId)

                    if (timeFramesOfTheDay.length > 0) { // NO WORKING HOUR FOR THE COMPANY AVAILABLE

                        // TODO: FILTER APPOINTMENTS FOR THAT DAY ONLY
                        let reservedTimeSlots = []

                        if (conflictingCalendars) {

                            Object.keys(googleReservedTimeSlots).map(calId => {

                                const calReservedTimeSlots = toJS(googleReservedTimeSlots[calId])
                                return reservedTimeSlots = reservedTimeSlots.concat(calReservedTimeSlots.map(resvdSlot => {
                                    return {
                                        ...resvdSlot,
                                        cushion_duration: googleTimeSlots[calId] && googleTimeSlots[calId].length && googleTimeSlots[calId][0]?.cushion_duration
                                    }
                                })
                                )
                            })

                        } else {
                            reservedTimeSlots = toJS(googleReservedTimeSlots[calendarId]).map(resvdSlot => {
                                return {
                                    ...resvdSlot,
                                    cushion_duration: googleTimeSlots[calendarId] && googleTimeSlots[calendarId].length && googleTimeSlots[calendarId][0]?.cushion_duration
                                }
                            });
                        }

                        // const reservationsOfTheDay = reservedTimeSlots.filter(timeSlot => moment.tz(day.format('YYYY-MM-DD'), DEFAULT_TIMEZONE)
                        //     .isSame(moment.tz(moment.tz(timeSlot.start, DEFAULT_TIMEZONE).format('YYYY-MM-DD'), DEFAULT_TIMEZONE)));

                        const reservationsOfTheDay = reservedTimeSlots;

                        if (!reservationsOfTheDay) continue

                        for (const timeFrame of timeFramesOfTheDay) {

                            const tempStartDate = moment.tz(timeFrame.start, timezone);

                            const tempEndDate = moment.tz(timeFrame.end, timezone);

                            const cushionDuration = timeFrame.cushion_duration;

                            for (let j = tempStartDate;
                                (j.isBefore(tempEndDate) &&
                                    tempEndDate.diff(j, 'minutes') >= (duration));
                            ) {
                                // If we already have a reserve time slot, then don't push it.
                                let timeSlotValid = true;
                                const timeSlotEndAt = moment.tz(j, timezone).add(duration + cushionDuration, 'minutes');
                                console.log(j.format());
                                console.log(timeSlotEndAt.format());

                                for (const reservedTime of reservationsOfTheDay) {

                                    const reservationCushionDuration = reservedTime.cushion_duration;
                                    const reservedStartTime = reservedTime.start && (reservedTime.start.dateTime || (reservedTime.start.date && (reservedTime.start.date + ' 00:00:00')))
                                    const reservedEndTime = reservedTime.end && (reservedTime.end.dateTime || (reservedTime.end.date && (reservedTime.end.date + ' 00:00:00')))

                                    if (!reservedStartTime || !reservedEndTime) continue

                                    const reservedEndTimeWithCushion = reservedTime.metadata && reservedTime.metadata.blocked && Number(reservedTime.metadata.blocked) === 1 ?
                                        moment.tz(reservedEndTime, timezone) : moment.tz(reservedEndTime, timezone).add(reservationCushionDuration, 'minutes')

                                    if ((moment.tz(reservedStartTime, timezone).isSameOrAfter(j)
                                        && moment.tz(reservedStartTime, timezone).isBefore(timeSlotEndAt)) ||
                                        (moment.tz(reservedEndTimeWithCushion, timezone).isAfter(j)
                                            && moment.tz(reservedEndTimeWithCushion, timezone).isBefore(timeSlotEndAt)) ||
                                        (moment.tz(reservedStartTime, timezone).isSameOrBefore(j)
                                            && moment.tz(reservedEndTimeWithCushion, timezone).isSameOrAfter(timeSlotEndAt))
                                    ) {

                                        timeSlotValid = false;

                                        if (reservedTime.metadata && reservedTime.metadata.blocked && Number(reservedTime.metadata.blocked) === 1) {
                                            j = moment.tz(reservedEndTime, timezone)
                                        } else {
                                            // j = moment.tz(reservedEndTime, timezone).add(cushionDuration, 'minutes')
                                            j = moment.tz(reservedEndTimeWithCushion, timezone)
                                        }
                                        break;
                                    }
                                }

                                if (timeSlotValid) {


                                    if (j.isAfter(moment.tz([], timezone))) {

                                        // If slot is available and in future
                                        const timeSlotBecomes = {
                                            start: j.format('hh:mma'),
                                            end: timeSlotEndAt.subtract(cushionDuration, 'minutes').format("hh:mma"),
                                            hour24: j.format('HH:mm')
                                        }

                                        // if (!freeTimeSlots.find(timeS => timeS.start === timeSlotBecomes.start && timeS.end === timeSlotBecomes.end)) {
                                        //     freeTimeSlots.push(timeSlotBecomes);
                                        // }

                                        if (!freeTimeSlots.find(timeS => timeS === timeSlotBecomes.hour24)) {
                                            freeTimeSlots.push(j.format('HH:mm'));
                                        }

                                        // Lets check next slot which will be 60 minutes later

                                        j.add(duration, 'minutes')

                                    } else {
                                        j.add(cushionDuration || 15, 'minutes')
                                    }

                                }
                            }
                        }
                    }

                    if (freeTimeSlots.length > 0) {
                        // Sort time slots here
                        freeTimeSlots.sort((a, b) => {
                            if (moment.tz(day.format('YYYY-MM-DD') + ' ' + a, DEFAULT_TIMEZONE).isBefore(
                                moment.tz(day.format('YYYY-MM-DD') + ' ' + b, DEFAULT_TIMEZONE))) {
                                return -1;
                            } else if (moment.tz(day.format('YYYY-MM-DD') + ' ' + a, DEFAULT_TIMEZONE).isAfter(
                                moment.tz(day.format('YYYY-MM-DD') + ' ' + b, DEFAULT_TIMEZONE))) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                        availableTimeSlots[month_year][moment.tz(day.format('YYYY-MM-DD') + ' 00:00', timezone).format()] =
                            freeTimeSlots;
                        // day.format will be in DEFAULT timezone.
                    }
                }
            }
            this.props.appStore.availableTimeSlots = availableTimeSlots;


            this.updateCalendar();
            this.props.toggleLoader();

            if (!availableTimeSlots[month_year] || Object.keys(availableTimeSlots[month_year]).length === 0) {
                this.props.showMessage(`No slots available for the month of "${moment().month(month - 1).format('MMMM')}"`, 'info');
            }
        }, 100)
    }

    // Call this method when someone changes timezone
    // Calculate free slots in their timezone and then initialize calendar
    updateCalendar() {
        const { month, year } = this.props.appStore;
        const { timezone, availableTimeSlots, enable: disable, timezoneAvailableTimeSlots } = this.props.appStore;
        const enable = [];
        const freeDates = availableTimeSlots[month + '_' + year];
        if (!freeDates) {
            return;
        }

        const startMonth = moment.tz([year, month - 1], timezone);
        const endOfMonth = moment.tz(startMonth, timezone).endOf('month');

        // const timezoneAvailableTimeSlots = {};

        for (let j = startMonth; j.isSameOrBefore(endOfMonth); j = j.add(1, 'day')) {

            Object.keys(freeDates).map(freeDate => {

                const estDate = moment.tz(freeDate, DEFAULT_TIMEZONE);

                // if (moment(j.format('YYYY-MM-DD')).isSame(userDate.format('YYYY-MM-DD'))) {
                const freeTimeSlots = freeDates[freeDate];
                return freeTimeSlots.forEach(slotDateAndTime => {

                    const estSlotDateAndTime = moment.tz(estDate.format('YYYY-MM-DD') + ' ' + slotDateAndTime,
                        DEFAULT_TIMEZONE);

                    const userSlotDateAndTime = moment.tz(estSlotDateAndTime, timezone);
                    if (moment(userSlotDateAndTime.format('YYYY-MM-DD')).isSame(j.format('YYYY-MM-DD'))) {
                        // Same Day add free slot
                        if (!timezoneAvailableTimeSlots[userSlotDateAndTime.format('YYYY-MM-DD')]) {
                            timezoneAvailableTimeSlots[userSlotDateAndTime.format('YYYY-MM-DD')] = [];
                        }
                        timezoneAvailableTimeSlots[userSlotDateAndTime.format('YYYY-MM-DD')]
                            .push(userSlotDateAndTime.format('HH:mm'));
                        if (!enable.includes(userSlotDateAndTime.format('YYYY-MM-DD'))) {
                            enable.push(userSlotDateAndTime.format('YYYY-MM-DD'));
                        }
                    }
                });

                // }
                // timezoneAvailableTimeSlots[availableDate] =
            })

        }

        this.props.appStore.timezoneAvailableTimeSlots = timezoneAvailableTimeSlots;
        // const disable = [];

        const start = moment.tz([year, month - 1], timezone);

        for (let k = start; k.isSameOrBefore(endOfMonth); k = k.add(1, 'day')) {

            if (!enable.includes(k.format('YYYY-MM-DD'))) {
                disable.push(k.format('YYYY-MM-DD'));
            }

        }
        // if (enable.length === 0) {
        //     // No day available for this month
        //
        //     // enable.push(endOfMonth.add(1, 'days').format('YYYY-MM-DD'));
        //
        //     // minDate = startMonth.format('YYYY-MM-DD');
        //
        // }
        this.props.appStore.enable = toJS(disable);
        // this.props.appStore.minDate = minDate;
    }

    handleChange = (selectedOption, data) => {

        this.props.appStore.timezone = data.value;
        this.props.appStore.timezoneLabel = selectedOption.target.innerText;
        this.updateCalendar();
    };

    handleDateChange = (event, { name, value }) => {

        const self = this;
        self.props.appStore.selectedDate = value;
        self.props.appStore.showTimeSlots = true;
    };

    activateCalendarHeaders() {
        // return;
        document.querySelector('i.left').onclick = () => {
            this.handleMonthChange(document.getElementsByTagName('th')[1].innerText, false);
        }

        document.querySelector('i.right').onclick = () => {
            this.handleMonthChange(document.getElementsByTagName('th')[1].innerText, true);
        }

        document.querySelector('th[colspan="5"]').onclick = (e) => {
            try {
                e.preventDefault()
                e.stopPropagation()
            } catch(e) {
                
            }
            // this.handleMonthChange(document.getElementsByTagName('th')[1].innerText, true);
        }    
    }

    askAQuestion = () => {
        this.setState({
            askAQuesionSelected: true
        })
    }

    render() {
        const {
            selectedDate,
            enable,
            minDate,
            calendars
        } = this.props.appStore;

        const { xs, sm, ask_question, userDetails, handleUpdateUserDetails } = this.props;
        const { calendarId, askAQuesionSelected } = this.state;

        return (
            <Grid container item
                xs={xs}
                sm={sm}
                alignItems={"center"}
                direction={"column"}
                style={{ padding: '2rem 0rem' }}
                className={"right-grid"}
                ref={this.myRef}
            >

                {
                    (!askAQuesionSelected && !calendarId) && <>
                        <h3 style={{ margin: '0px' }}>What would you like to make an appointment for?</h3>
                        <Divider className={"divider"} />
                        <div style={{ width: '25rem' }}>

                            {
                                calendars.map(calendar => {
                                    if (calendar.primary) return <></>
                                    return <Segment>
                                        <Radio name="calendar" onChange={() => { this.onCalendarSelect(calendar.id) }} label={calendar.summary} />
                                    </Segment>
                                })
                            }
                            {
                                (ask_question) && <Segment>
                                    <Radio name="calendar" onChange={this.askAQuestion} label={"Ask Question" } />
                                </Segment>
                            }

                        </div>
                    </>
                }

                {
                    (!askAQuesionSelected && calendarId) &&
                    <>
                        <h3 style={{ margin: '0px' }}>Choose a day and time!</h3>
                        <Divider className={"divider"} />
                        <div className={"center"}>
                            <h3>Which day is best for you?</h3>

                            <DateInput
                                name="date"
                                dateFormat={"YYYY-MM-DD"}
                                placeholder="Date"
                                value={selectedDate}
                                onChange={this.handleDateChange.bind(this)}
                                inline={true}
                                pickerWidth={'35rem'}
                                disable={enable}
                                markColor={'orange'}
                                minDate={minDate}
                            />
                            {/*// enable={enable}*/}

                        </div>
                    </>
                }

                {
                    askAQuesionSelected && <>
                    <h3 style={{ margin: '0px' }}>Ask Question!</h3>
                        <Divider className={"m-divider"} style={{
                            margin: '2rem auto !important'
                        }}/>

                        <div style={{width: '100%'}}>
                            <UserDetailsForm 
                                handleUpdateUserDetails={handleUpdateUserDetails}
                                userDetails={userDetails} 
                                onSubmitUserDetails={this.props.submitQuestion}
                                askAQuesionSelected={askAQuesionSelected}
                            />
                        </div>
                    </>
                }
            </Grid>
        );
    }
}));
// }



