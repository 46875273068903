import React, {Component} from 'react';
import { Grid } from '@material-ui/core';
import CalendarGrid from "../../grids/google-calendar-grids/CalendarGrid";
import TimeSlotGrid from "../../grids/google-calendar-grids/TimeSlotGrid";
import { inject, observer} from 'mobx-react';
import moment from "moment-timezone";
import { CHATBOT_SERVER } from "../../constants";
import axios from 'axios';
// export default class DateSlotPicker extends Component {
export default inject('appStore')(observer(class ScheduleConsultation extends Component {

    QueryStringToJSON() {
        var pairs = window.location.search ? window.location.search.slice(1).split('&') : [];

        var result = {};
        pairs.forEach(function (pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        return JSON.parse(JSON.stringify(result));
    }

    constructor (props) {
        super(props);
        const queryParams = this.QueryStringToJSON();
        if (!queryParams['senderId'] || !queryParams['companyId']) {
            alert('Something is wrong! Please close this window and try again.');
            return;
        }

        this.state = {
            senderId: queryParams['senderId'],
            returnUrl: queryParams['returnUrl'],
            botId: queryParams['botId'],
            threadId: queryParams['threadId'],
            channel: queryParams['channel'],
            companyId: queryParams['companyId'],
            calendarId: queryParams['calendarId'] || null,
            interval: queryParams['interval'],
            previousHoldId: queryParams['holdId'] || null,
            message: null
        }
    }

    componentDidMount() {

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'Messenger'));

        window.extAsyncInit = function() {
            // the Messenger Extensions JS SDK is done loading

        };
    }

    timeSlotSelected = () => {

        const self = this;
        const {timezone, selectedDate, slotTime, holdId} = this.props.appStore;
        const start = selectedDate + ' ' + slotTime;
        const selectedDateTime = moment.tz(start, timezone);
        // const selectedDateTimeInDefaultTimeZone = moment.tz(selectedDateTime, DEFAULT_TIMEZONE);

        self.props.toggleLoader();

        const returnUrl = self.state.returnUrl || CHATBOT_SERVER;
        const previousHoldId = self.state.previousHoldId;

        const message = 'Success! Please return to the chat session to continue reserving your free consultation.'

        axios.post(returnUrl, {
            senderId: self.state.senderId,
            selectedDateTime: selectedDateTime.toString(),
            timezone: timezone,
            botId: self.state.botId,
            threadId: self.state.threadId,
            channel: self.state.channel,
            holdId
        })
            .then(response => {
                console.log(response);

                if (previousHoldId) this.props.deleteHold && this.props.deleteHold(previousHoldId)

                try {
                    window.MessengerExtensions.requestCloseBrowser(function success() {
                        // webview closed
                        self.props.toggleLoader();
                    }, function error(err) {
                        self.props.toggleLoader();
                        // an error occurred
                    });
                } catch (e) {
                    console.log(e);
                    self.props.toggleLoader();
                }

                this.setState({
                    message
                })

            })
            .catch(error => {
                self.props.toggleLoader();
                console.log(error);
                this.setState({
                    message
                })
                
            });


    }

    render() {

        const {showTimeSlots} = this.props.appStore;
        const message = this.state && this.state.message;

        const {companyId, calendarId, interval} = this.state
        return (
            <>
            {
                message && 
                    <p>{message}</p>
            }

            {
                !message &&
                <Grid container spacing={0} className={"appRoot"}>

                {(!showTimeSlots) &&

                <CalendarGrid
                    toggleLoader = {this.props.toggleLoader}
                    xs={12}
                    sm={12}
                    companyId={companyId}
                    calendarId={calendarId} 
                    duration={interval || 60}
                    conflictingCalendars={true}
                    showMessage={this.props.showMessage}
                />
                }
                {
                    showTimeSlots &&
                    <TimeSlotGrid
                        toggleLoader = {this.props.toggleLoader}
                        goToPage = {this.props.goToPage}
                        xs={12}
                        sm={12}
                        timeSlotSelected={this.timeSlotSelected}
                        companyId={companyId}
                        calendarId={calendarId} 
                        duration={interval || 60}
                        interval={interval}
                        showMessage={this.props.showMessage}
                    />
                }

            </Grid>
            }
           
            </>
        );
    }
}));
// }



