import React, {Component} from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
// import ScheduleConsultation from './calendar/ScheduleConsultation';
import Consultation from './calendar/Consultation';
// import { makeStyles } from '@material-ui/core/styles';
import {
    Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";
import history from './history';
import DateSlotPicker from "./zebylbot-components/date-slot-picker/DateSlotPicker";
import ZebylBotForm from "./zebylbot-components/zebylbot-form/zebylbot-form"
import AdminAvailability from "./zebylbot-components/admin-availability/admin-availability"
import stores from './stores';
import { Provider } from 'mobx-react';
import LoaderComponent from './components/loader';
import Toaster from './components/toaster';
import PortalGoogleCalendar from './zebylbot-components/date-slot-picker/PortalGoogleCalendar';
import axios from 'axios';
import { PORTALAPIS } from './constants';

export default class App extends Component {

    toggleLoader = () => {
        stores.store.loader = !stores.store.loader;
    }


    handleClose = (event, reason) => {
         stores.store.open = false;
    };

    showMessage  = (message, variant = "error") => {
        stores.store.open = true;
        stores.store.message = message;
        stores.store.variant = variant
    }

    deleteHold = (holdId) => {

        axios.post(PORTALAPIS.DELETE_HOLD_SLOT, {
            holdId
        })
        .then(res => {
            if (res && res.status === 200) console.log('Hold deleted')
        })
        .catch(e => {
            console.log(e)
        })
    }

    goToPage =(page) => {

        if (page === 1) {

            stores.store.showTimeSlots = false;
            stores.store.showUserForm = false;
            // this.activateCalendarHeaders();
        } else if (page === 2) {

            stores.store.showTimeSlots = true;
            stores.store.showUserForm = false;

            const { holdId } = stores.store;

            if (holdId) this.deleteHold(holdId)

        }
    }

    render() {

        // const useStyles2 = makeStyles(theme => ({
        //     margin: {
        //         margin: theme.spacing(1),
        //     },
        // }));

        return (
            <div>

                <Provider
                    appStore={stores.store}
                    history={history}
                >
                    <div>
                        <LoaderComponent />
                        <Toaster handleClose={this.handleClose}/>
                        <Router history={history}>
                            <Switch>
                                <Redirect exact path="/" to = '/meeting'/>
                                <Route exact path="/meeting" component={() => <Consultation
                                    goToPage={this.goToPage}
                                    history={history}
                                    toggleLoader={this.toggleLoader} showMessage={this.showMessage}/>}/>
                                <Route exact path="/meeting/:username" component={(props) => <Consultation
                                    goToPage={this.goToPage}
                                    {...props}
                                    toggleLoader={this.toggleLoader} showMessage={this.showMessage}/>}/>
                                <Route path="/pick-time-slot" component={() =><DateSlotPicker
                                    goToPage={this.goToPage}
                                    toggleLoader={this.toggleLoader} showMessage={this.showMessage}/>}/>
                                <Route path="/pick-google-calendar-time-slot" component={() =><PortalGoogleCalendar
                                    goToPage={this.goToPage} deleteHold={this.deleteHold}
                                    toggleLoader={this.toggleLoader} showMessage={this.showMessage}/>}/>
                                <Route path="/zebylbot-form" component={() =><ZebylBotForm
                                    goToPage={this.goToPage}
                                    toggleLoader={this.toggleLoader} showMessage={this.showMessage}/>}/>
                                <Route path="/admin-available" component={() =><AdminAvailability
                                    toggleLoader={this.toggleLoader} showMessage={this.showMessage}/>}/>
                            </Switch>
                        </Router>
                    </div>
                </Provider>
            </div>
        );
    }
}
