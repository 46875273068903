import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from "moment-timezone";
import axios from 'axios';
import { Form } from 'semantic-ui-react';
import { Grid } from '@material-ui/core';
import {
    DateInput,
} from 'semantic-ui-calendar-react';
import { DEFAULT_TIMEZONE } from "../../constants";

const minDate = moment.tz(DEFAULT_TIMEZONE).format('YYYY-MM-DD');
// export default class DateSlotPicker extends Component {
export default inject('appStore')(observer(class ZebylBotForm extends Component {

    QueryStringToJSON() {
        var pairs = window.location.search ? window.location.search.slice(1).split('&') : [];

        var result = {};
        pairs.forEach(function (pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        return JSON.parse(JSON.stringify(result));
    }

    constructor(props) {
        super(props);

        this.state = {
            message: null,
            setupLocation: '',
            city: '',
            groundCovering: '',
            date: '',
            errorReports: {}
        }

        const queryParams = this.QueryStringToJSON();
        if (!queryParams['senderId']) {
            return alert('Something is wrong! Please close this window and try again.');
        }

        this.state = {
            senderId: queryParams['senderId'],
            returnUrl: queryParams['returnUrl'],
            botId: queryParams['botId'],
            threadId: queryParams['threadId'],
            channel: queryParams['channel'],
            message: null,
            setupLocation: '',
            city: '',
            groundCovering: '',
            date: '',
            errorReports: {}
        }
    }

    componentDidMount() {

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'Messenger'));

        window.extAsyncInit = function () {
            // the Messenger Extensions JS SDK is done loading

        };
    }

    submitForm = () => {

        const self = this;
        const {
            setupLocation,
            groundCovering,
            date,
            errorReports,
            zipcode
        } = this.state;        

        if (!setupLocation) {

            return this.setState({
                errorReports: Object.assign(errorReports, {
                    'setupLocation': true
                })
            })
        }

        if (!groundCovering) {
            return this.setState({
                errorReports: Object.assign(errorReports, {
                    'groundCovering': true
                })
            })
        }

        self.props.toggleLoader();

        const returnUrl = self.state.returnUrl;

        axios.post(returnUrl, {
            senderId: self.state.senderId,
            setupLocation,
            zipcode,
            groundCovering,
            date,
            botId: self.state.botId,
            threadId: self.state.threadId,
            channel: self.state.channel
        })
            .then(response => {
                console.log(response);
                try {
                    window.MessengerExtensions.requestCloseBrowser(function success() {
                        // webview closed
                        self.props.toggleLoader();
                    }, function error(err) {
                        self.props.toggleLoader();
                        // an error occurred
                    });
                } catch (e) {
                    console.log(e);
                    self.props.toggleLoader();
                }

                this.setState({
                    message: 'Success! Please return to the chat session to continue with the chat.'
                })

            })
            .catch(error => {
                self.props.toggleLoader();
                console.log(error);
                this.setState({
                    message: 'Success! Please return to the chat session to continue with the chat.'
                })

            });


    }

    handleChange = (e, { name, value }) => {

        const { errorReports } = this.state

        this.setState({
            errorReports: Object.assign(errorReports, {
                [name]: !value
            })
        })

        this.setState({ [name]: value }, () => {

            // const {
            //     setupLocation,
            //     city,
            //     groundCovering,
            //     date
            // } = this.state;
        })
    }

    // state = {
    //     setupLocation: 'Back yard',
    //     city: '',
    //     groundCovering: 'Concrete',
    //     date: ''
    // }

    handleInvalidInput = (event) => {
        if (event.target.name === 'phone') {
            event.target.setCustomValidity('Please enter a 10-digit phone number.');
        } else if (event.target.name === 'website') {
            event.target.setCustomValidity('Website url is not valid.');
        }
    }

    handleDateChange = (event, {name, value}) => {


        this.setState({
            [name]: value
        })

    }

    handleInvalidInput =(event) => {
        if (event.target.name === 'zipcode') {
            event.target.setCustomValidity('Please provide a 5-digit zipcode');
        }
    }

    handleOpen = (e, { name, value }) => {

        const { errorReports } = this.state

        this.setState({
            errorReports: Object.assign(errorReports, {
                [name]: false
            })
        })
    }

    // handleClose = (e, { name, value }) =>  {
    //     const { errorReports } = this.state

    //     setTimeout(() => {
    //         this.setState({
    //             errorReports: Object.assign(errorReports, {
    //                 [name]: !value
    //             })
    //         })
    //     }, 100)
        
    // }

    render() {
    const { setupLocation, zipcode, groundCovering, date, message, /* city */ errorReports } = this.state;
        const setupLocationDropdownOptions = [
            { key: 'empty_value_yard', text: 'Please select an opton', value: '' },
            { key: 'by', text: 'Back yard', value: 'Back yard' },
            { key: 'fy', text: 'Front yard', value: 'Front yard' },
        ];

        const groundCoveringDropdownOptions = [
            { key: 'empty_value_ground', text: 'Please select an opton', value: '' },
            { key: 'cgc', text: 'Concrete', value: 'Concrete' },
            { key: 'ggc', text: 'Grass', value: 'Grass' },
            { key: 'ggc', text: 'Dirt', value: 'Dirt' },
            { key: 'dggc', text: 'Dry grass', value: 'Dry grass' },
            { key: 'dgsgc', text: 'Dead grass', value: 'Dead grass' },
        ];


        return (
            <>
            {
                message && <p>{message}</p>
            }
            {
                !message && <Grid 
                container
                spacing={0}
                xs={12}
                sm={12}
                alignItems={"center"}
                direction={"column"}
                style={{padding:'2rem 0rem'}}
                className={"right-grid"}
            >
                <Form autocomplete="off" className="zebylbot-form" onSubmit={this.submitForm}>
                    <Form.Dropdown
                        options={setupLocationDropdownOptions}
                        label='Where will the jumper be setup?'
                        placeholder='Please select an opton'
                        fluid
                        search
                        selection
                        name={'setupLocation'}
                        value={setupLocation}
                        text={setupLocation}
                        required
                        onChange={this.handleChange}
                        onOpen={this.handleOpen}
                        error={errorReports['setupLocation']}
                        onClose={this.handleClose}
                    />
                    {/* <Form.Input fluid label='City' placeholder='City' required
                        name='city'
                        value={city}
                        onChange={this.handleChange}
                        maxLength={"60"}
                    /> */}

                    <Form.Input fluid label='Zipcode' placeholder='Zipcode'
                        name='zipcode'
                        value={zipcode}
                        onChange={this.handleChange}
                        required
                        pattern={"^[0-9]{5}$"}
                        onInvalid={this.handleInvalidInput}
                        maxLength={"5"}
                        onInput={(e) => {e.target.setCustomValidity('')}}
                    />

                    <Form.Dropdown
                        options={groundCoveringDropdownOptions}
                        label='Type of ground convering?'
                        placeholder='Please select an opton'
                        fluid
                        search
                        selection
                        name={'groundCovering'}
                        value={groundCovering}
                        text={groundCovering}
                        required
                        onChange={this.handleChange}
                        error={errorReports['groundCovering']}
                        onOpen={this.handleOpen}
                        onClose={this.handleClose}
                    />

                    {/* <Form.Input fluid label='Date' placeholder='Date' required
                        name='date'
                        value={date}
                        onChange={this.handleChange}
                    /> */}

                    <div className="required field" style={{marginBottom: '0rem'}}>
                    <label>Date</label>
                    </div>
                    <DateInput
                        name="date"
                        dateFormat={"YYYY-MM-DD"}
                        placeholder="Date"
                        value={date}
                        onChange={this.handleDateChange}
                        pickerWidth={'35rem'}
                        markColor={'orange'}
                        minDate={minDate}
                        required
                        closable={true}
                        autocomplete="off"
                    />

                    <Form.Button primary style={{ backgroundColor: '#f15a29', color: 'white' }}>Start the fun!</Form.Button>
                </Form>
            </Grid>
            }
           
            </>
        );
    }
}));
// }



