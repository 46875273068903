import React, {Component} from 'react';
import {  Form  } from 'semantic-ui-react';
import {PROVINCES} from '../constants';


export default class UserDetailsForm extends Component {
// export default inject('calendar')(observer(class ScheduleConsultation extends Component {
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value }, () => {

            const {
                email,
                phone,
                firstname,
                lastname,
                street,
                city,
                province,
                zipcode,
                question
            } = this.state;
    
            this.props.handleUpdateUserDetails({
                email,
                phone,
                firstname,
                lastname,
                street,
                city,
                province,
                zipcode,
                question
            });
        })
    }

    handleSubmit = () => {

        const {
            email,
            phone,
            firstname,
            lastname,
            street,
            city,
            province,
            zipcode,
            question
        } = this.state;

        this.props.onSubmitUserDetails({
            email,
            phone,
            firstname,
            lastname,
            street,
            city,
            province,
            zipcode,
            question
        });
    }

    state = {
        email:  this.props.userDetails.email || '',
        phone:  this.props.userDetails.phone || '',
        firstname:  this.props.userDetails.firstname || '',
        lastname:  this.props.userDetails.lastname || '',
        province: this.props.calendarId === 'lhjnf6cn6f8g88lqui3uol2r50@group.calendar.google.com' ? 'California' : ''
    }

    handleInvalidInput =(event) => {
        if (event.target.name === 'phone') {
            event.target.setCustomValidity('Please enter a 10-digit phone number.');
        } else if (event.target.name === 'website') {
            event.target.setCustomValidity('Website url is not valid.');
        }  else if (event.target.name === 'zipcode') {
            event.target.setCustomValidity('Zip code is not valid.');
        }
    }

    render() {
        const { 
            email,
            phone,
            firstname,
            lastname,
            street,
            city,
            province,
            zipcode,
            question
        } = this.state;

        const { calendarId, askAQuesionSelected } = this.props;
        
        return (
            <Form className="user-detail-form"  onSubmit={this.handleSubmit}>
                {/* <Form.Dropdown
                    options={subjectOptions}
                    label='Select a topic to be discussed'
                    placeholder='Select a topic to be discussed'
                    fluid
                    search
                    selection
                    name={'subject'}
                    value={subject}
                    text={subject}
                    required
                    onChange={this.handleChange}
                /> */}
                <Form.Group widths='equal'>
                    <Form.Input fluid label='First name' placeholder='First name' required
                                name='firstname'
                                value={firstname}
                                onChange={this.handleChange}
                                maxLength={"60"}
                    />
                    
                </Form.Group>

                <Form.Input 
                    fluid label='Last name' placeholder='Last name' required
                    name='lastname'
                    value={lastname}
                    onChange={this.handleChange}
                    maxLength={"60"}
                />

                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid label='Email' placeholder='Email' required type={"email"}
                        name='email'
                        value={email}
                        onChange={this.handleChange}
                        maxLength={"60"}
                    />
                </Form.Group>

                <Form.Input 
                    fluid
                    label='Phone'
                    placeholder='Phone'
                    required
                    name='phone'
                    value={phone}
                    pattern={"^[0-9]{10,11}$"}
                    onChange={this.handleChange}
                    onInvalid={this.handleInvalidInput}
                    onInput={(e) => {e.target.setCustomValidity('')}}
                />

                {
                calendarId === 'lhjnf6cn6f8g88lqui3uol2r50@group.calendar.google.com' && <>
                    <Form.Group widths='equal'>
                        <Form.Input 
                            fluid
                            label='Street Address' 
                            placeholder='Street Address' 
                            required
                            name='street'
                            value={street}
                            onChange={this.handleChange}
                            maxLength={"200"}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>

                    <Form.Input 
                        fluid
                        label='City' 
                        placeholder='City' 
                        required
                        name='city'
                        value={city}
                        onChange={this.handleChange}
                        maxLength={"100"}
                    />

                    <Form.Dropdown 
                        placeholder='Select Province'
                        options={PROVINCES}
                        search
                        selection
                        onChange={this.handleChange.bind(this)}
                        value={province}
                        disabled = {calendarId === 'lhjnf6cn6f8g88lqui3uol2r50@group.calendar.google.com'}
                        fluid
                        label='Province' 
                        required
                        name='province'
                    />

                    {/* <Form.Input 
                        fluid
                        label='Province' 
                        placeholder='Province' 
                        required
                        name='province'
                        value={province}
                        onChange={this.handleChange}
                        maxLength={"100"}
                    /> */}

                    <Form.Input 
                        fluid
                        label='Zip code' 
                        placeholder='Zip code' 
                        required
                        name='zipcode'
                        value={zipcode}
                        onChange={this.handleChange}
                        maxLength={"5"}
                        pattern={"^[0-9]{5}$"}
                        onInvalid={this.handleInvalidInput}
                        onInput={(e) => {e.target.setCustomValidity('')}}
                    />

                </Form.Group>
                </>
                }
                
                {
                    askAQuesionSelected && <Form.TextArea 
                        rows={5}
                        required
                        label='Question' 
                        placeholder='Type your question here..'
                        name='question'
                        value={question}
                        onChange={this.handleChange}
                        onInput={(e) => {e.target.setCustomValidity('')}}
                    />
            
                }

                <Form.Button primary style={{backgroundColor: '#f15a29', color: 'white'}}>Proceed</Form.Button>
            </Form>
        );
    }
// }));
}



