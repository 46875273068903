import React, { Component } from "react";
import axios from "axios";
import { inject, observer } from "mobx-react";
import { PORTALAPIS } from "../constants";
import ScheduleGoogleConsultation from "./ScheduleGoogleConsultation";
import ScheduleConsultation from "./ScheduleConsultation";

export default inject("appStore")(
  observer(
    class Consultation extends Component {
      getUserConfiguration(username) {
        return axios.get(PORTALAPIS.CONFIGURATION, {
          params: {
            username,
          },
        });
      }

      constructor(props) {
        super(props);

        // Here I need to get Configuration of the client

        this.state = {};
      }

      componentDidMount() {
        const username = this.props?.match?.params?.username;

        if (!username)
          return this.setState({
            calendarType: "odoo",
          });

        this.props.toggleLoader();
        this.getUserConfiguration(username)
          .then((response) => {
            console.log(response);

            if (response && response.status === 200 && response.data) {
              const data = response.data && response.data;

              if (data && data.config) {
                return this.setState({
                  username,
                  ...data.config,
                });
              }
            }

            return this.setState({
              calendarType: "odoo",
            });
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.props.toggleLoader();
          });
      }

      render() {
        const {
          calendarType,
          companyId,
          owner,
          designation,
          companyName,
          profile_picture,
          north_county_bg,
          website,
          logo,
          ask_question,
          username,
        } = this.state;

        if (!calendarType) return <></>;

        return (
          <div>
            {calendarType === "google" ? (
              <ScheduleGoogleConsultation
                {...this.props}
                username={username}
                companyId={companyId}
                owner={owner}
                designation={designation}
                companyName={companyName}
                profile_picture={profile_picture}
                north_county_bg={north_county_bg}
                website={website}
                logo={logo}
                conflictingCalendars={true}
                ask_question={ask_question}
                {...this.state}
              />
            ) : (
              <ScheduleConsultation {...this.props} />
            )}
          </div>
        );
      }
    }
  )
);
// }
