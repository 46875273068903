import React, {Component} from 'react';
import {  Form  } from 'semantic-ui-react';


export default class UserDetailsForm extends Component {
// export default inject('calendar')(observer(class ScheduleConsultation extends Component {
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value }, () => {

            const {
                subject,
                company_name,
                email,
                phone,
                comment,
                firstname,
                lastname,
                website,
            } = this.state;
    
            this.props.handleUpdateUserDetails({
                subject,
                company_name,
                email,
                phone,
                comment: comment || '',
                firstname,
                lastname,
                website,
            });
        })
    }

    handleSubmit = () => {
        const {
            subject,
            company_name,
            email,
            phone,
            comment,
            firstname,
            lastname,
            website,
        } = this.state;

        this.props.onSubmitUserDetails({
            subject,
            company_name,
            email,
            phone,
            comment: comment || 'NA',
            firstname,
            lastname,
            website,
        });
    }

    state = {
        subject: this.props.userDetails.subject || 'Text Messaging for Business',
        company_name: this.props.userDetails.company_name || '',
        email:  this.props.userDetails.email || '',
        phone:  this.props.userDetails.phone || '',
        comment:  this.props.userDetails.comment || '',
        firstname:  this.props.userDetails.firstname || '',
        lastname:  this.props.userDetails.lastname || '',
        website:  this.props.userDetails.website || '',
    }

    handleInvalidInput =(event) => {
        if (event.target.name === 'phone') {
            event.target.setCustomValidity('Please enter a 10-digit phone number.');
        } else if (event.target.name === 'website') {
            event.target.setCustomValidity('Website url is not valid.');
        }
    }

    render() {
        const {subject, company_name, email, phone, comment, firstname, lastname, website} = this.state;
        const subjectOptions = [
            { key: 'tmfb', text: 'Text Messaging for Business', value: 'Text Messaging for Business' },
            { key: 'tm', text: 'Text Marketing', value: 'Text Marketing' },
            { key: 'cb', text: 'Chatbots', value: 'Chatbots' },
            { key: 'vts', text: 'VoIP Telephone System', value: 'VoIP Telephone System' },
            { key: 'csd', text: 'Custom Software Development', value: 'Custom Software Development' },
            { key: 'bor', text: 'Boost Online Reviews', value: 'Boost Online Reviews' },
            { key: 'tympd', text: 'Transform Your Medical Practice Digitally', value: 'Transform Your Medical Practice Digitally' },
        ];
        return (
            <Form className="user-detail-form"  onSubmit={this.handleSubmit}>
                <Form.Dropdown
                    options={subjectOptions}
                    label='Select a topic to be discussed'
                    placeholder='Select a topic to be discussed'
                    fluid
                    search
                    selection
                    name={'subject'}
                    value={subject}
                    text={subject}
                    required
                    onChange={this.handleChange}
                />
                <Form.Input fluid label='Company name' placeholder='Company name' required
                            name='company_name'
                            value={company_name}
                            onChange={this.handleChange}
                            maxLength={"60"}
                />
                <Form.Group widths='equal'>
                    <Form.Input fluid label='First name' placeholder='First name' required
                                name='firstname'
                                value={firstname}
                                onChange={this.handleChange}
                                maxLength={"60"}
                    />
                    <Form.Input fluid label='Last name' placeholder='Last name' required
                                name='lastname'
                                value={lastname}
                                onChange={this.handleChange}
                                maxLength={"60"}
                    />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Input fluid label='Email' placeholder='Email' required type={"email"}
                                name='email'
                                value={email}
                                onChange={this.handleChange}
                                maxLength={"60"}
                    />
                    <Form.Input fluid
                                label='Phone'
                                placeholder='Phone'
                                required
                                name='phone'
                                value={phone}
                                pattern={"^[0-9]{10,11}$"}
                                onChange={this.handleChange}
                                onInvalid={this.handleInvalidInput}
                                onInput={(e) => {e.target.setCustomValidity('')}}
                    />
                </Form.Group>
                <Form.Input

                            fluid label='Website'
                            placeholder='http://example.com'
                            required
                            name='website'
                            value={website}
                            onChange={this.handleChange}
                            pattern={'^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$'}
                            maxLength={"200"}
                            onInvalid={this.handleInvalidInput}
                            onInput={(e) => {e.target.setCustomValidity('')}}
                />
                <Form.TextArea label='Comments' placeholder='Tell us more about your business..'
                               name='comment'
                               value={comment}
                               onChange={this.handleChange}
                />
                <Form.Button primary style={{backgroundColor: '#f15a29', color: 'white'}}>Schedule Meeting</Form.Button>
            </Form>
        );
    }
// }));
}



