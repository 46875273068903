import React, {Component} from 'react';
import { Grid, Divider } from '@material-ui/core';
import { Dropdown } from 'semantic-ui-react';
import {
    DateInput,
} from 'semantic-ui-calendar-react';
// import moment from 'moment';
import axios from 'axios';
import moment from 'moment-timezone';
import {TIMEZONES} from '../constants';
import { inject, observer} from 'mobx-react';
import { DEFAULT_TIMEZONE, APIS, POST_BODY, DEFAULTTIMESLOTS } from "../constants";
import { toJS } from 'mobx'


// export default class CalendarGrid extends Component {
export default inject('appStore')(observer(class CalendarGrid extends Component {

    state = {};

    getTimeSlots =() =>{
        return axios({
            method: 'post',
            url: APIS.SLOTS,
            headers: { 'Content-Type': 'application/json' },
            data: POST_BODY,
            withCredentials: false,
        })
    }

    getReservedTimeSlots =() =>{
        return axios.post(APIS.RESERVED, {
            ...POST_BODY
        })
    }

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    componentDidMount() {
        var self = this;
        if (this.props.appStore.timeSlots.length === 0 && this.props.appStore.reservedTimeSlots.length === 0) {
            this.props.toggleLoader();
            axios.all([this.getTimeSlots(), this.getReservedTimeSlots()])
                .then(axios.spread(function (responseTimeSlots, responseReserve) {
                    // Both requests are now complete
                    self.props.toggleLoader();
                    const timeSlots = responseTimeSlots.data.result;
                    const reservedTimeSlots = responseReserve.data.result;
                    self.props.appStore.timeSlots = timeSlots;
                    self.props.appStore.reservedTimeSlots = reservedTimeSlots;

                    self.CalculateFreeTimeSlots();

                })).catch(err => {
                // DEFAULT FALLBACK
                this.props.appStore.timeSlots = DEFAULTTIMESLOTS;
                self.CalculateFreeTimeSlots();
                console.log(err);
                self.props.toggleLoader();
            });
        } else {
            self.CalculateFreeTimeSlots();
        }


        self.activateCalendarHeaders();
    }

    componentDidUpdate () {
        if (this.myRef && this.myRef.current) {
            window.scrollTo(0, this.myRef.current.offsetTop);

        }
    }

    handleMonthChange(currentString, isForward) {
        let [month, year] = currentString.split(" ");
        let monthNumber = +moment().month(month).format('M');

        year = Number(year);
        monthNumber = Number(monthNumber);

        if (isForward) {
            monthNumber += 1;
        } else {
            monthNumber -= 1;
        }

        if (monthNumber === 13) {
            monthNumber = 1;
            year+=1
        } else if (monthNumber === 0) {
            monthNumber = 12;
            year-=1;
        }

        if (monthNumber < +moment().format('M') && year ===  +moment().format('YYYY')) {
            return;
        }

        this.props.appStore.month = monthNumber;
        this.props.appStore.year = year;
        this.CalculateFreeTimeSlots();
    }

    // Call this method when calendar month and year changes.
    CalculateFreeTimeSlots () {
        const { month, year } = this.props.appStore;
        var month_year = month + '_' + year;

        const  { availableTimeSlots } = this.props.appStore;
        if (!toJS(availableTimeSlots)[month_year]) {
            availableTimeSlots[month_year] = {};
        } else {
            return this.updateCalendar();
        }

        const { timeSlots, reservedTimeSlots } = this.props.appStore;
        const currentMonth = moment.tz(DEFAULT_TIMEZONE).format('M');
        // GET start and end date of month
        let startDate, endDate;
        if (month === currentMonth) {
            startDate = moment.tz([], DEFAULT_TIMEZONE); // Start from today
        } else {
            startDate = moment.tz([year, month - 1], DEFAULT_TIMEZONE);
        }

        endDate = moment.tz(startDate, DEFAULT_TIMEZONE).endOf('month');
        for (let day = startDate; day.isSameOrBefore(endDate); day = day.add(1, 'day')) {
            // Time slots available for day of the week
            const timeFramesOfTheDay = timeSlots.filter(frame =>
                (frame.day.toLowerCase() === day.format('dddd').toLowerCase() &&
                    (!frame.date || (moment.tz(day.format('YYYY-MM-DD'), DEFAULT_TIMEZONE)
                            .isSame(moment.tz(frame.date, DEFAULT_TIMEZONE))
                    ))));


            const freeTimeSlots = [];
            if (timeFramesOfTheDay.length > 0) {
                const reservationsOfTheDay = reservedTimeSlots.filter(timeSlot => moment.tz(day.format('YYYY-MM-DD'), DEFAULT_TIMEZONE)
                    .isSame(moment.tz(moment.tz(timeSlot.start, DEFAULT_TIMEZONE).format('YYYY-MM-DD'), DEFAULT_TIMEZONE)));

                for (const timeFrame of timeFramesOfTheDay) {

                    let startTime = timeFrame.start;
                    let endTime = timeFrame.end;

                    startTime = startTime.split(':');

                    if (startTime[0].length === 1) {
                        startTime[0] = '0' + startTime[0];
                    }

                    if (startTime[1].length === 1) {
                        startTime[1] = '0' + startTime[1];
                    }

                    startTime = startTime.join(':');

                    endTime = endTime.split(':');

                    if (endTime[0].length === 1) {
                        endTime[0] = '0' + endTime[0];
                    }

                    if (endTime[1].length === 1) {
                        endTime[1] = '0' + endTime[1];
                    }

                    endTime = endTime.join(':');

                    const tempStartDate = moment.tz(day.format('YYYY-MM-DD') + ' ' + startTime, DEFAULT_TIMEZONE);

                    const tempEndDate = moment.tz(day.format('YYYY-MM-DD') + ' ' + endTime, DEFAULT_TIMEZONE);
                    for (let j = tempStartDate;
                         (j.isBefore(tempEndDate) &&
                             tempEndDate.diff(j, 'minutes') >= 45);
                         j.add(1, 'hours')) {
                        // If we already have a reserve time slot, then don't push it.
                        let timeSlotValid = true;
                        const timeSlotEndAt = moment.tz(j, DEFAULT_TIMEZONE).add(45, 'minutes');
                        for (const reservedTime of reservationsOfTheDay) {
                            if ((moment.tz(reservedTime.start, DEFAULT_TIMEZONE).isSameOrAfter(j)
                                && moment.tz(reservedTime.start, DEFAULT_TIMEZONE).isBefore(timeSlotEndAt)) ||
                                (moment.tz(reservedTime.end, DEFAULT_TIMEZONE).isAfter(j)
                                    && moment.tz(reservedTime.end, DEFAULT_TIMEZONE).isBefore(timeSlotEndAt)) ||
                                (moment.tz(reservedTime.start, DEFAULT_TIMEZONE).isSameOrBefore(j)
                                    && moment.tz(reservedTime.end, DEFAULT_TIMEZONE).isSameOrAfter(timeSlotEndAt))
                            ) {

                                timeSlotValid = false;
                            }
                        }

                        if (timeSlotValid) {
                            if (j.isAfter(moment.tz([], DEFAULT_TIMEZONE))) {
                                freeTimeSlots.push(j.format('HH:mm'));
                            }
                        }
                    }
                }
            }

            if (freeTimeSlots.length > 0) {
                // Sort time slots here
                freeTimeSlots.sort((a, b) => {
                    if (moment.tz(day.format('YYYY-MM-DD') + ' ' + a, DEFAULT_TIMEZONE).isBefore(
                        moment.tz(day.format('YYYY-MM-DD') + ' ' + b, DEFAULT_TIMEZONE))) {
                        return -1;
                    } else if (moment.tz(day.format('YYYY-MM-DD') + ' ' + a, DEFAULT_TIMEZONE).isAfter(
                        moment.tz(day.format('YYYY-MM-DD') + ' ' + b, DEFAULT_TIMEZONE))){
                        return 1;
                    } else {
                        return 0;
                    }
                });
                availableTimeSlots[month_year][moment.tz(day.format('YYYY-MM-DD') + ' 00:00', DEFAULT_TIMEZONE).format()] =
                    freeTimeSlots;
                // day.format will be in DEFAULT timezone.
            }
        }
        this.props.appStore.availableTimeSlots = availableTimeSlots;
        this.updateCalendar();
    }

    // Call this method when someone changes timezone
    // Calculate free slots in their timezone and then initialize calendar
    updateCalendar() {
        const {month, year} = this.props.appStore;
        const {timezone, availableTimeSlots} = this.props.appStore;
        const enable = [];
        const freeDates = availableTimeSlots[month + '_' + year];
        if (!freeDates) {
            return;
        }

        const startMonth = moment.tz([year, month - 1], timezone);
        const endOfMonth = moment.tz(startMonth, timezone).endOf('month');

        const timezoneAvailableTimeSlots = {};

        for (let j = startMonth; j.isSameOrBefore(endOfMonth); j = j.add(1, 'day')) {

            Object.keys(freeDates).map(freeDate => {

                const estDate = moment.tz(freeDate, DEFAULT_TIMEZONE);

                // if (moment(j.format('YYYY-MM-DD')).isSame(userDate.format('YYYY-MM-DD'))) {
                const freeTimeSlots = freeDates[freeDate];
                return freeTimeSlots.forEach(slotDateAndTime => {

                    const estSlotDateAndTime = moment.tz(estDate.format('YYYY-MM-DD') + ' ' + slotDateAndTime,
                        DEFAULT_TIMEZONE);

                    const userSlotDateAndTime = moment.tz(estSlotDateAndTime, timezone);
                    if (moment(userSlotDateAndTime.format('YYYY-MM-DD')).isSame(j.format('YYYY-MM-DD'))) {
                        // Same Day add free slot
                        if (!timezoneAvailableTimeSlots[userSlotDateAndTime.format('YYYY-MM-DD')]) {
                            timezoneAvailableTimeSlots[userSlotDateAndTime.format('YYYY-MM-DD')] = [];
                        }
                        timezoneAvailableTimeSlots[userSlotDateAndTime.format('YYYY-MM-DD')]
                            .push(userSlotDateAndTime.format('HH:mm'));
                        if(!enable.includes(userSlotDateAndTime.format('YYYY-MM-DD'))) {
                            enable.push(userSlotDateAndTime.format('YYYY-MM-DD'));
                        }
                    }
                });

                // }
                // timezoneAvailableTimeSlots[availableDate] =
            })

        }

        this.props.appStore.timezoneAvailableTimeSlots = timezoneAvailableTimeSlots;
        const disable = [];

        const start = moment.tz([year, month - 1], timezone);

        for (let k = start; k.isSameOrBefore(endOfMonth); k = k.add(1, 'day')) {

            if(!enable.includes(k.format('YYYY-MM-DD'))) {
                disable.push(k.format('YYYY-MM-DD'));
            }

        }
            // if (enable.length === 0) {
        //     // No day available for this month
        //
        //     // enable.push(endOfMonth.add(1, 'days').format('YYYY-MM-DD'));
        //
        //     // minDate = startMonth.format('YYYY-MM-DD');
        //
        // }
        this.props.appStore.enable = disable;
        // this.props.appStore.minDate = minDate;
    }

    handleChange = (selectedOption, data) => {

        this.props.appStore.timezone = data.value;
        this.props.appStore.timezoneLabel = selectedOption.target.innerText;
        this.updateCalendar();
    };

    handleDateChange = (event, {name, value}) => {

        const self = this;
        self.props.appStore.selectedDate = value;
        self.props.appStore.showTimeSlots = true;
    };

    activateCalendarHeaders() {
        // return;
        document.querySelector('i.left').onclick = () => {
            this.handleMonthChange(document.getElementsByTagName('th')[1].innerText, false);
        }

        document.querySelector('i.right').onclick = () => {
            this.handleMonthChange(document.getElementsByTagName('th')[1].innerText, true);
        }
    }


    render() {
        const {
            selectedDate,
            timezone,
            enable,
            minDate,
        } = this.props.appStore;

        const {xs, sm} = this.props;

        return (
            <Grid container item
                  xs={xs}
                  sm={sm}
                  alignItems={"center"}
                  direction={"column"}
                  style={{padding:'2rem 0rem'}}
                  className={"right-grid"}
                  ref={this.myRef}
            >

                <h3 style={{margin: '0px'}}>Schedule a meeting with Pejman!</h3>
                <Divider className={"divider"}/>
                <div className={"center"}>
                    <h3>Which day is best for you?</h3>

                    <Dropdown placeholder='Select Timezone'
                              options={TIMEZONES}
                              search
                              selection
                              onChange={this.handleChange.bind(this)}
                              style={{width: '75%'}}
                              value={timezone}
                    />

                    <DateInput
                        name="date"
                        dateFormat={"YYYY-MM-DD"}
                        placeholder="Date"
                        value={selectedDate}
                        onChange={this.handleDateChange.bind(this)}
                        inline={true}
                        pickerWidth={'35rem'}

                        disable={enable}
                        markColor={'orange'}
                        minDate={minDate}
                    />
                    {/*// enable={enable}*/}

                </div>
            </Grid>
        );
    }
}));
// }



