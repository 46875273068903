import React, {Component} from 'react';
import { Grid, Divider } from '@material-ui/core';
import {  List,  Icon} from 'semantic-ui-react'
import moment from 'moment-timezone';
import { inject, observer} from 'mobx-react';


// export default class CalendarGrid extends Component {
export default inject('appStore')(observer(class TimeSlotGrid extends Component {

    state = {};

    selectedTimeSlot = (slot) => {

        this.props.appStore.showUserForm = true;
        this.props.appStore.showTimeSlots = false;
        this.props.appStore.slotTime = slot;
        if (this.props.timeSlotSelected) {
            this.props.timeSlotSelected();
        }

    };

    componentDidMount() {}


    render() {
        const {
            selectedDate,
            showTimeSlots,
            timezone,
            timezoneLabel,
            timezoneAvailableTimeSlots
        } = this.props.appStore;
        let timeSlots = [];
        if (showTimeSlots) {
            timeSlots = timezoneAvailableTimeSlots[selectedDate] || [];
        }

        const {xs, sm} = this.props;

        return (
            <Grid container item
                  xs={xs}
                  sm={sm}
                  alignItems={"center"}
                  direction={"column"}
                  className={"right-grid"}
            >
                <div className={'step-header'}>
                    <Icon className={"back-icon"}
                          onClick={this.props.goToPage.bind(this, 1)}
                          name={"arrow left"} size={"large"}>

                    </Icon>
                    <h4>{moment.tz(selectedDate, timezone).format('dddd, MMMM DD, YYYY')}</h4>
                </div>

                <Divider  className={"divider"}/>
                <h3 style={{marginTop: '0px'}}>What time works best?</h3>
                <p>{timezoneLabel}</p>
                <div className={"center"} style={{width: '100%', margin: '1rem 0rem',  maxWidth: '550px'}}>
                    <div>
                        {
                            timeSlots.map((slot, index) => {

                                const selectedDateTime =  moment.tz(
                                    moment.tz(selectedDate, timezone).format('YYYY-MM-DD') +
                                    ' ' + slot, timezone
                                );

                                const slotStart = selectedDateTime.format('hh:mm A');
                                const slotEnd = moment.tz(selectedDateTime.add(45, 'minutes'), timezone)
                                    .format('hh:mm A');

                                return <div onClick={this.selectedTimeSlot.bind(this, slot)}
                                            className={"time-slot"} key={slot + '_' + index}>
                                    <List.Content>
                                        <List.Header>{slotStart + ' - ' + slotEnd}</List.Header>
                                    </List.Content>
                                </div>
                            })
                        }
                    </div>
                </div>
            </Grid>
        );
    }
}));
// }



