import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import axios from "axios";
import { Button } from "semantic-ui-react";
import { Box, Grid } from "@material-ui/core";
import logo from "../../zebyl-logo.png";

// export default class DateSlotPicker extends Component {
export default inject("appStore")(
  observer(
    class AdminAvailability extends Component {
      QueryStringToJSON() {
        var pairs = window.location.search
          ? window.location.search.slice(1).split("&")
          : [];

        var result = {};
        pairs.forEach(function (pair) {
          pair = pair.split("=");
          result[pair[0]] = decodeURIComponent(pair[1] || "");
        });

        return JSON.parse(JSON.stringify(result));
      }

      constructor(props) {
        super(props);

        this.state = {};

        const queryParams = this.QueryStringToJSON();
        if (!queryParams["senderId"]) {
          return alert(
            "Something is wrong! Please close this window and try again."
          );
        }

        this.state = {
          senderId: queryParams["senderId"],
          returnUrl: queryParams["returnUrl"],
          botId: queryParams["botId"],
          threadId: queryParams["threadId"],
          channel: queryParams["channel"],
        };
      }

      onBtnClick = (available) => {
        const self = this;

        self.props.toggleLoader();

        const returnUrl = self.state.returnUrl;

        axios
          .get(returnUrl, {
            params: {
              senderId: self.state.senderId,
              botId: self.state.botId,
              threadId: self.state.threadId,
              channel: self.state.channel,
              available,
            },
          })
          .then((response) => {
            console.log(response);
            try {
              self.props.toggleLoader();
            } catch (e) {
              self.props.toggleLoader();
            }

            this.setState({
              message: available
                ? "Thanks! Your response has been noted and gracefully relayed to the audience.You may safely close this window."
                : "No worries. Do come back and let me know when you'll be available next.You may safely close this window.",
            });
          })
          .catch((error) => {
            self.props.toggleLoader();
            console.log(error);

            this.setState({
              message: "Something went wrong. Please refresh and try again.",
            });
          });
      };

      render() {
        const { message } = this.state;

        return (
          <>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              mx="auto"
              py={5}
              style={{ width: "100%", maxWidth: "600px" }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <img src={logo} height="60px" alt="" />
                <p style={{ color: "#ee754e", fontWeight: 700, fontSize: 20 }}>
                  Virtual Assistant Quick Poll
                </p>
              </Box>
              {!message ? (
                <Box
                  p={3}
                  mt={3}
                  width="100%"
                  style={{ borderRadius: 10, background: "#e9eaef" }}
                >
                  <div className="main-card">
                    <p
                      style={{
                        color: "green",
                        fontWeight: "700",
                        marginTop: "10px",
                        fontSize: 20,
                      }}
                    >
                      Checking to see if you are available!
                    </p>
                    <p style={{ opacity: ".5" }}>
                      Please select from the options below:
                    </p>
                  </div>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    gap={2}
                    my={2}
                  >
                    <Button
                      icon="thumbs up outline"
                      onClick={() => {
                        this.onBtnClick(true);
                      }}
                      positive
                    >
                      I'm Available
                    </Button>
                    <Button
                      onClick={() => {
                        this.onBtnClick(false);
                      }}
                      negative
                    >
                      I'm NOT Available
                    </Button>
                  </Box>
                  <p className="note">
                    <b>Note:</b>{" "}
                    <span style={{ opacity: ".5" }}>
                      The audience will not see your response. Its between you
                      and I.
                    </span>
                  </p>
                </Box>
              ) : (
                <Box
                  p={3}
                  mt={3}
                  width="100%"
                  style={{ borderRadius: 10, background: "#e9eaef" }}
                >
                  <p className="note">
                    <b>{message}</b>
                  </p>
                </Box>
              )}
            </Box>
          </>
        );
      }
    }
  )
);
// }
