import React, {Component} from 'react';
import { Grid, Divider, Button } from '@material-ui/core';
import {  Image, Card, Icon} from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment-timezone';
import UserDetailsForm from '../user-details/user-details-form';
import pejman from '../pejman.jpg'
import zebyl from '../zebyl-logo.png';
import introduction from '../introduction.png';
// import calendar from '../calendar-success.png';
import CalendarGrid from '../grids/CalendarGrid';
import TimeSlotGrid from '../grids/TimeSlotGrid';
import { inject, observer} from  'mobx-react';
import { DEFAULT_TIMEZONE, APIS, POST_BODY, THANKYOUPAGE, INACTIVITYTIMER } from "../constants";

const CONSULTATION_INTEREST = {
    Texting: 'Text Messaging for Business',
    Textmarketing: 'Text Marketing',
    Chatbots: 'Chatbots',
    HostedVoip: 'VoIP Telephone System',
    Software: 'Custom Software Development',
    Reviews: 'Boost Online Reviews',
    Medical_Webinar: 'Transform Your Medical Practice Digitally'
};

// export default class ScheduleConsultation extends Component {
export default inject('appStore')(observer(class ScheduleConsultation extends Component {

    state = {
        userDetails: {},
        redirectToThankYouPage: false
    }

    QueryStringToJSON() {
        var pairs = window.location.search ? window.location.search.slice(1).split('&') : [];

        var result = {};
        pairs.forEach(function (pair) {
            pair = pair.split('=');
            result[decodeURIComponent(pair[0]).trim()] = decodeURIComponent(pair[1] || '');
        });

        return JSON.parse(JSON.stringify(result));
    }

   

    constructor (props) {
        super(props);

        const queryParams = this.QueryStringToJSON();

        this.state = {
            userDetails: {
                company_name: queryParams['company'] || '',
                email: queryParams['email'] || queryParams['Email'] || '',
                phone: queryParams['phone'] || queryParams['Phone'] || queryParams['phone_number']|| '',
                firstname: queryParams['firstName'] || queryParams['first_name'] || queryParams['First Name'] || '',
                lastname: queryParams['lastName'] || queryParams['last_name'] || queryParams['Last Name'] || '',
                website: queryParams['website'] || '',
                subject: (queryParams['interest'] && CONSULTATION_INTEREST[queryParams['interest']]) ?
                         CONSULTATION_INTEREST[queryParams['interest']]: 
                         (queryParams['Interest'] && CONSULTATION_INTEREST[queryParams['Interest']] ?
                         CONSULTATION_INTEREST[queryParams['Interest']] :'')
            },
            redirectToThankYouPage: (queryParams && typeof queryParams === 'object' && Object.keys(queryParams).length) ? true: false,
            returnUrl: queryParams['returnUrl'] || '',
        }
    }

    componentDidMount() {
        
    }

    handleUpdateUserDetails = (userDetails) => {
        this.setState({
            userDetails
        })
    }

    onSubmitUserDetails = async (userDetails) => {

        const self = this;
        self.props.toggleLoader();
        self.setState({
            userDetails
        })

        const { returnUrl} = this.state;
        const {timezone, selectedDate, slotTime} = this.props.appStore;
        const start = selectedDate + ' ' + slotTime;
        const selectedDateTime = moment.tz(start, timezone);
        // const selectedDateTimeInDefaultTimeZone = moment.tz(selectedDateTime, DEFAULT_TIMEZONE);
        const body = Object.assign({}, POST_BODY);
        Object.assign(body.params, {
            // start: selectedDateTimeInDefaultTimeZone.toString(),
            start: selectedDateTime.toString(),
            tz: timezone,
            source: 'Zebyl Consultation Calendar',
            ...userDetails
        });

        axios({
            method: 'post',
            url: APIS.RESERVE_CONSULTATION,
            headers: { 'content-type': 'application/json' },
            data: body,
        })
            .then(resp => {
                self.props.toggleLoader();
                if (resp && resp.status === 200) {
                    const respData = resp.data;
                    if (respData.result.success) {

                        this.props.appStore.showUserForm = false;
                        this.props.appStore.showSuccessResult = true;

                        // IF we get a return url, will go to return url immediatley else will wait for 60 seconds

                        setTimeout(() => {
                            window.open(returnUrl || (THANKYOUPAGE + '?completed=free_consult'), '_self');
                        }, returnUrl ? 3500: INACTIVITYTIMER)

                    } else {
                        const errorMessage = respData && respData.result && respData.result.error;
                        if (errorMessage && errorMessage.toLowerCase().includes('booked')) {
                            self.props.showMessage('Requested time slot has already been booked by another user.' +
                                '\nPlease choose a different time slot');
                            self.handleTimeSlotAlreadyBooked();
                        } else {
                            this.props.appStore.showUserForm = false;
                            this.props.appStore.showSuccessResult = true;
                        }
                    }
                } else {

                    this.props.appStore.showUserForm = false;
                    this.props.appStore.showSuccessResult = true;

                }
            })
            .catch(error => {
                self.props.toggleLoader();
                this.props.appStore.showUserForm = false;
                this.props.appStore.showSuccessResult = true;
            })
    }

    handleTimeSlotAlreadyBooked() {
        const self = this;
        const {enable, timezoneAvailableTimeSlots, selectedDate, slotTime, timezone, availableTimeSlots,  month, year}
        = self.props.appStore;
        const timeSlots = timezoneAvailableTimeSlots[selectedDate];
        const availableTimeSlotsOfTheDay = timeSlots.filter(timeSlot => timeSlot !== slotTime);
        if (availableTimeSlotsOfTheDay.length <= 0) {
            enable.splice( enable.indexOf(selectedDate), 1 ); // Disable the date
        }
        timezoneAvailableTimeSlots[selectedDate] = availableTimeSlotsOfTheDay;

        // Remove time slot from availableTimeSlots saved as default timezone

        try {

            const start = selectedDate + ' ' + slotTime;
            const selectedDateTime = moment.tz(start, timezone);
            const selectedDateTimeInDefaultTimeZone = moment.tz(selectedDateTime, DEFAULT_TIMEZONE);
            const key = moment.tz(selectedDateTimeInDefaultTimeZone.format('YYYY-MM-DD') + ' 00:00', DEFAULT_TIMEZONE).format()
            const slotToRemove = selectedDateTimeInDefaultTimeZone.format('HH:mm');

            const filteredAvailableTimeSlots = availableTimeSlots[month + '_' + year][key].filter(slot => slot !== slotToRemove);
            availableTimeSlots[month + '_' + year][key] = filteredAvailableTimeSlots;
        }
        catch (e) {

        }
        ////////////////////////////////////////////////////////////////////
        self.props.appStore.showUserForm = false;
        self.props.appStore.enable = enable;
        self.props.appStore.showTimeSlots = availableTimeSlotsOfTheDay.length > 0;
        self.props.appStore.timezoneAvailableTimeSlots = timezoneAvailableTimeSlots;
        self.props.appStore.availableTimeSlots = availableTimeSlots;
    }

    goToThankYouPage = () => {
        var self = this;
        self.props.toggleLoader();
        window.open(THANKYOUPAGE + '?completed=free_consult', '_self');
    }

    render() {
        const {
            selectedDate,
            slotTime,
            showTimeSlots,
            timezone,
            showUserForm,
            showSuccessResult
        } = this.props.appStore;

        const {userDetails} = this.state;
        return (
            <Grid container spacing={0} className={"appRoot"}>
                <Grid container item xs={12} sm={5}
                      alignItems={"center"}
                      justify={"start"}
                      direction={"column"}
                      className={"left-grid"}
                >

                    <div className={'orange-section'}>
                        <Card className={"introductoryCard"}>
                            <Card.Content>

                                <Grid container spacing={2}>
                                    <Grid container item xs={4}
                                          sm={4}
                                          md={4}
                                          alignItems={"center"}
                                          justify={"end"}
                                          direction={"row"}
                                    >
                                        <Image
                                            src={pejman}
                                            size={'small'}
                                            className={"circular-image"}
                                        />

                                    </Grid>

                                    <Grid container item
                                          xs={8}
                                          sm={8}
                                          md={8}
                                          alignItems={"center"}
                                          justify={"start"}
                                          direction={"row"}
                                    >
                                        <div className={"meta"}>
                                            <span className={"header"}>PEJMAN RAJABIAN</span>
                                            <span style={{whiteSpace: 'nowrap'}}>Chief Technology Advisor</span><br />
                                            <span>Zebyl Inc.</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card.Content>
                        </Card>
                    </div>

                    <div className={'introduction-section'}>

                        <p className={"introduction-orange"}>
                           According to Gartner, by 2022, <strong>72%</strong> of customer
                           interactions will involve an emerging technology such
                           as machine learning applications, chatbots, or mobile
                           messaging.
                        </p>
                        {/*<Divider className={"p-divider"}/>*/}
                        <Image
                            src={zebyl}
                            size={'tiny'}
                            className={"zebyl-logo"}
                        />
                        <p className={"introduction-extra"}>
                            Find out how Zebyl, our conversational
                            communications platform, can take your marketing,
                            sales, and customer service to a new level of
                            efficiency.
                        </p>
                        <Image
                            src={introduction}
                            size={'tiny'}
                            className={"introduction-logo"}
                        />
                    </div>
                </Grid>

                {(!showTimeSlots && !showUserForm && !showSuccessResult) &&

                    <CalendarGrid
                        toggleLoader = {this.props.toggleLoader}
                        xs={12}
                        sm={7}
                    />
                }
                {
                    showTimeSlots &&
                    <TimeSlotGrid
                        toggleLoader = {this.props.toggleLoader}
                        goToPage = {this.props.goToPage}
                        xs={12}
                        sm={7}
                    />
                }

                {
                    showUserForm &&
                    <Grid container item
                          xs={12}
                          sm={7}
                          alignItems={"center"}
                          direction={"column"}
                          className={"right-grid"}
                    >
                        <div className={'step-header'}>
                            <Icon
                                onClick={this.props.goToPage.bind(this, 2)}
                                className={"back-icon"}
                                name={"arrow left"} size={"large"}>

                            </Icon>
                            <h4>{moment.tz(selectedDate + ' ' + slotTime, timezone).format('LLLL')}</h4>
                        </div>
                        <Divider className={"divider"}/>
                        <UserDetailsForm handleUpdateUserDetails={this.handleUpdateUserDetails} userDetails={userDetails} onSubmitUserDetails={this.onSubmitUserDetails}/>
                    </Grid>
                }

                {
                    showSuccessResult &&
                    <Grid container item
                          xs={12}
                          sm={7}
                          alignItems={"center"}
                          justify={"space-around"}
                          direction={"column"}
                          className={"right-grid"}
                    >


                        <div className={"center"}>
                            {/*<Image*/}
                                {/*src={calendar}*/}
                                {/*size={'small'}*/}
                                {/*style={{margin: 'auto'}}*/}
                            {/*/>*/}
                            <Icon color='orange' name='calendar check outline' size='massive' />
                            <h3>A consultation meeting has been scheduled on {moment.tz(selectedDate + ' ' + slotTime, timezone).format('dddd, LL')} at {moment.tz(selectedDate + ' ' + slotTime, timezone).format('hh:mm A')}!</h3>
                            <p>Please look out for a meeting confirmation email in your inbox.</p>

                            <Button onClick={this.goToThankYouPage} variant="contained" size="large" style={{backgroundColor: '#f15a29', color: 'white'}}>
                                Ok
                            </Button>


                            {/*<Grid container spacing={2}>*/}
                                {/*<Grid item sm={4}>*/}
                                    {/*<Icon name='calendar check outline'*/}
                                          {/*size='huge'*/}
                                          {/*color={"orange"}*/}
                                    {/*/>*/}
                                {/*</Grid>*/}
                                {/*<Grid item sm={8}>*/}
                                    {/*<Grid item direction="column" spacing={2}>*/}
                                        {/*<h2>Excellent!</h2>*/}
                                        {/*<p>Meeting has been scheduled</p>*/}
                                    {/*</Grid>*/}
                                {/*</Grid>*/}
                            {/*</Grid>*/}
                        </div>
                    </Grid>

                }

            </Grid>
        );
    }
}));
// }



