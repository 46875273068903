import React, { Component } from 'react';
import { Grid, Divider, Button } from '@material-ui/core';
import CalendarGrid from "../grids/google-calendar-grids/CalendarGrid";
import TimeSlotGrid from "../grids/google-calendar-grids/TimeSlotGrid";
import { inject, observer } from 'mobx-react';
import moment from "moment-timezone";
import { CHATBOT_SERVER, PORTALAPIS } from "../constants";
import axios from 'axios';
import { Image, Card, Icon } from 'semantic-ui-react';
import UserDetailsForm from '../user-details/google-user-detail-form';
// export default class DateSlotPicker extends Component {
export default inject('appStore')(observer(class ScheduleGoogleConsultation extends Component {

    QueryStringToJSON() {
        var pairs = window.location.search ? window.location.search.slice(1).split('&') : [];

        var result = {};
        pairs.forEach(function (pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        return JSON.parse(JSON.stringify(result));
    }

    constructor(props) {
        super(props);

        if (!props['companyId']) {
            return alert('Something is wrong! Please close this window and try again.');
        }

        const queryParams = this.QueryStringToJSON();

        this.state = {
            companyId: props['companyId'],
            calendarId: queryParams['calendarId'] || null,
            message: null,
            userDetails: {},
            questionSubmitted: false
        }
    }

    componentDidMount() {

    }

    updateCalendarId = (calendarId) =>{
        this.setState({
            calendarId
        })
    }

    updateInterval = (interval) =>{
        this.setState({
            interval
        })
    }

    timeSlotSelected = () => {

        const self = this;
        const { timezone, selectedDate, slotTime } = this.props.appStore;
        const start = selectedDate + ' ' + slotTime;
        const selectedDateTime = moment.tz(start, timezone);
        // const selectedDateTimeInDefaultTimeZone = moment.tz(selectedDateTime, DEFAULT_TIMEZONE);

        self.props.toggleLoader();

        const returnUrl = self.state.returnUrl || CHATBOT_SERVER;

        axios.post(returnUrl, {
            senderId: self.state.senderId,
            selectedDateTime: selectedDateTime.toString(),
            timezone: timezone,
            botId: self.state.botId,
            threadId: self.state.threadId,
            channel: self.state.channel
        })
            .then(response => {
                console.log(response);
                try {
                    window.MessengerExtensions.requestCloseBrowser(function success() {
                        // webview closed
                        self.props.toggleLoader();
                    }, function error(err) {
                        self.props.toggleLoader();
                        // an error occurred
                    });
                } catch (e) {
                    console.log(e);
                    self.props.toggleLoader();
                }

                this.setState({
                    message: 'Please return to the chat session to continue your free consultation.'
                })

            })
            .catch(error => {
                self.props.toggleLoader();
                console.log(error);
                this.setState({
                    message: 'Please return to the chat session to continue your free consultation.'
                })
            });
    }

    handleUpdateUserDetails = (userDetails) => {
        this.setState({
            userDetails
        })
    }

    onSubmitUserDetails = async (userDetails) => {

        const self = this;
        self.props.toggleLoader();

        const { interval, companyId, calendarId } = this.state;
        self.setState({
            userDetails
        })

        const { selectedDate, slotTime, holdId, timezone } = this.props.appStore;
        const start = selectedDate + ' ' + slotTime;
        const selectedDateTime = moment.tz(start, timezone);
        const endTime = moment.tz(start, timezone).add(interval, 'minutes')
        // const selectedDateTimeInDefaultTimeZone = moment.tz(selectedDateTime, DEFAULT_TIMEZONE);
        const body = {};

        const {calendars} = this.props.appStore
        const {companyName} = this.props;

        const calendarName = calendars.find(cal => (cal.primary && calendarId === 'primary') || cal.id === calendarId)?.summary
        Object.assign(body, {
            startTime: selectedDateTime,
            endTime: endTime,
            companyId: companyId,
            calendarId,
            ...userDetails,
            reservationName:  `${calendarName} appointment scheduled on ${selectedDateTime.format("ddd MMM D, YYYY hh:mma")} - ${endTime.format("hh:mma")} `,
            "reminders": {
                "useDefault": false,
                "overrides": [{
                    "method": "email",
                    "minutes": 60
                }]
            },
            holdId,
            checkSlotAvailablity: true,
            address: [
                (userDetails.street || ''),
                (userDetails.city || ''),
                (userDetails.province || ''), 
                (userDetails.zipcode || '')
            ].join(' ').trim(),
            call_source: 'consultation_calendar'
        });

        axios({
            method: 'post',
            url: PORTALAPIS.RESERVE_CONSULTATION,
            headers: { 'content-type': 'application/json' },
            data: body,
        })
        .then(resp => {
            self.props.toggleLoader();
            if (resp && resp.status === 200) {
                const respData = resp.data;
                if (respData.success) {

                    this.props.appStore.showUserForm = false;
                    this.props.appStore.showSuccessResult = true;

                    
                } else {
                    this.props.appStore.showUserForm = false;
                    this.props.appStore.showSuccessResult = true;
                }
            } else {

                this.props.appStore.showUserForm = false;
                this.props.appStore.showSuccessResult = true;

            }
        })
        .catch(error => {
            self.props.toggleLoader();
            this.props.appStore.showUserForm = false;
            this.props.appStore.showSuccessResult = true;
        })
    }

    goToWebsite = () => {
        const {website} = this.props;

        window.open(website, '_self');

    }

    submitQuestion = (userDetails) => {

        const { username } = this.props;
        const self = this;
        self.props.toggleLoader();

         axios({
            method: 'post',
            url: PORTALAPIS.SUBMIT_A_QUESTION,
            headers: { 'content-type': 'application/json' },
            data: {
                ...userDetails,
                username
            },
        })
        .then(resp => {
            this.setState({
                questionSubmitted: true
            })
        })
        .catch(error => {
            console.log('error caught while sending question', error)
        })
        .finally(() => {
            self.props.toggleLoader();
        })

    }

    render() {

        const { companyId, calendarId, interval } = this.state

        if (!companyId) return <></>

    const { owner, designation, companyName, profile_picture, north_county_bg, introduction, conflictingCalendars, /* logo */ } = this.props;
        const {
            selectedDate,
            slotTime,
            showTimeSlots,
            timezone,
            showUserForm,
            showSuccessResult
        } = this.props.appStore;

        const { userDetails, questionSubmitted } = this.state;

        // const message = this.state && this.state.message;

        return (
            <Grid container spacing={0} className={"appRoot"}>
                <Grid container item xs={12} sm={5}
                    alignItems={"center"}
                    justify={"start"}
                    direction={"column"}
                    className={"left-grid"}
                >

                    <div className={'orange-section'}>
                        <Card className={"introductoryCard"}>
                            <Card.Content>

                                <Grid container spacing={2}>
                                    <Grid container item xs={4}
                                        sm={4}
                                        md={4}
                                        alignItems={"center"}
                                        justify={"end"}
                                        direction={"row"}
                                    >
                                        <Image
                                            src={profile_picture}
                                            size={'small'}
                                            className={"circular-image"}
                                        />

                                    </Grid>

                                    <Grid container item
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        alignItems={"center"}
                                        justify={"start"}
                                        direction={"row"}
                                    >
                                        <div className={"meta"}>
                                            <span className={"header"}>{owner}</span><br />
                                            <span style={{ whiteSpace: 'nowrap' }}>{designation}</span><br />
                                            <span>{companyName}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card.Content>
                        </Card>
                    </div>

                    <div className={'introduction-section'}>

                        <p className={"introduction-orange"} style={{backgroundColor: 'rgb(46 156 231)'}}>
                            {introduction}
                        </p>
                        {/*<Divider className={"p-divider"}/>*/}
                        {/* <Image
                            src={logo}
                            size={'tiny'}
                            className={"company-logo"}
                        />
                        <p className={"introduction-extra"}>
                            Find out how Zebyl, our conversational
                            communications platform, can take your marketing,
                            sales, and customer service to a new level of
                            efficiency.
                        </p> */}
                        <Image
                            src={north_county_bg}
                            size={'tiny'}
                            className={"introduction-logo"}
                        />
                    </div>
                </Grid>

                {(!questionSubmitted && !showTimeSlots && !showUserForm && !showSuccessResult) &&

                    <CalendarGrid
                        toggleLoader={this.props.toggleLoader}
                        xs={12}
                        sm={7}
                        companyId={companyId}
                        calendarId={calendarId}
                        updateCalendarId={this.updateCalendarId}
                        updateInterval={this.updateInterval}
                        conflictingCalendars={conflictingCalendars}
                        showMessage={this.props.showMessage}
                        calendarsToShow={this.props.calendarsToShow}
                        ask_question={this.props.ask_question}
                        submitQuestion={this.submitQuestion}
                        userDetails={userDetails}
                        handleUpdateUserDetails={this.handleUpdateUserDetails}
                    />
                }
                {
                    showTimeSlots &&
                    <TimeSlotGrid
                        toggleLoader={this.props.toggleLoader}
                        goToPage={this.props.goToPage}
                        xs={12}
                        sm={7}
                        timeSlotSelected={this.timeSlotSelected}
                        companyId={companyId}
                        calendarId={calendarId}
                        duration={interval}
                        interval={interval}
                        showMessage={this.props.showMessage}
                    />
                }

                {
                    showUserForm &&
                    <Grid container item
                        xs={12}
                        sm={7}
                        alignItems={"center"}
                        direction={"column"}
                        className={"right-grid"}
                    >
                        <div className={'step-header'}>
                            <Icon
                                onClick={this.props.goToPage.bind(this, 2)}
                                className={"back-icon"}
                                name={"arrow left"} size={"large"}>

                            </Icon>
                            <h4>{moment.tz(selectedDate + ' ' + slotTime, timezone).format('LLLL')}</h4>
                        </div>
                        <Divider className={"divider"} />
                        <UserDetailsForm 
                            handleUpdateUserDetails={this.handleUpdateUserDetails}
                            userDetails={userDetails} 
                            onSubmitUserDetails={this.onSubmitUserDetails}
                            calendarId={calendarId}
                        />
                    </Grid>
                }

                {
                    showSuccessResult &&
                    <Grid container item
                        xs={12}
                        sm={7}
                        alignItems={"center"}
                        justify={"space-around"}
                        direction={"column"}
                        className={"right-grid"}
                    >


                        <div className={"center"}>
                            <Icon color='orange' name='calendar check outline' size='massive' />
                            <h3>A consultation meeting has been scheduled on {moment.tz(selectedDate + ' ' + slotTime, timezone).format('dddd, LL')} at {moment.tz(selectedDate + ' ' + slotTime, timezone).format('hh:mm A')}!</h3>
                            <p>Please look out for a meeting confirmation email in your inbox.</p>

                            <Button onClick={this.goToWebsite} variant="contained" size="large" style={{ backgroundColor: '#f15a29', color: 'white' }}>
                                Ok
                            </Button>
                        </div>
                    </Grid>

                }

                {
                    (questionSubmitted) &&
                    <Grid container item
                        xs={12}
                        sm={7}
                        alignItems={"center"}
                        justify={"space-around"}
                        direction={"column"}
                        className={"right-grid"}
                    >


                        <div className={"center"}>
                            <Icon color='orange' name='calendar check outline' size='massive' />
                            <h3>Your question has been submitted!</h3>
                            <p>You will receive a response from us soon..</p>

                            <Button onClick={this.goToWebsite} variant="contained" size="large" style={{ backgroundColor: '#f15a29', color: 'white' }}>
                                Ok
                            </Button>
                        </div>
                    </Grid>

                }

            </Grid>
        );
    }
}));