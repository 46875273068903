// export const CHATBOT_SERVER = "https://0e68c5fd.ngrok.io/zebyl/date-time-slot-input";
export const CHATBOT_SERVER = "https://zebylbot.textaspect.com/zebyl/date-time-slot-input";

export const DEFAULT_TIMEZONE = 'America/New_York';
export const INACTIVITYTIMER = 600000;
export const THANKYOUPAGE = 'https://www.zebyl.com/thank-you';

export const APIS = {
    SLOTS: 'https://accounting.textaspect.com/api/book/calendar/timeframe',
    RESERVED: 'https://accounting.textaspect.com/api/book/calendar/events',
    RESERVE_CONSULTATION: 'https://accounting.textaspect.com/api/book/calendar/create',
};

export const PORTALAPIS = {
    SLOTS: 'https://portal.zebyl.com/get-working-hours',
    RESERVED: 'https://portal.zebyl.com/get-all-appointments',
    CONFIGURATION: 'https://portal.zebyl.com/consultation/get-consultation-config',
    RESERVE_CONSULTATION: 'https://portal.zebyl.com/book_appointment',
    CALENDARS: 'https://portal.zebyl.com/get-calendar-list',
    HOLD_SLOT: 'https://portal.zebyl.com/hold-slot',
    DELETE_HOLD_SLOT: 'https://portal.zebyl.com/rockbox/delete-hold',
    SUBMIT_A_QUESTION: 'https://portal.zebyl.com/consultation/submit-a-question'
}

export const POST_BODY = {
    jsonrpc: '2.0',
    method: 'call',
    params: {
        calendar_id: '1',
    },
};

export const DEFAULTTIMESLOTS = [{
    "day": "monday",
    "start": "11:0",
    "end": "16:0"
},
    {
        "day": "tuesday",
        "start": "11:0",
        "end": "16:0"
    },
    {
        "day": "wednesday",
        "start": "15:0",
        "end": "17:0"
    },
    {
        "day": "monday",
        "start": "8:0",
        "end": "9:0"
    },
    {
        "day": "friday",
        "start": "8:0",
        "end": "9:0"
    },
    {
        "day": "friday",
        "start": "15:0",
        "end": "17:0"
    },
    {
        "day": "thursday",
        "start": "13:0",
        "end": "15:0"
    }
];

export const TIMEZONES = [
    { text: '(GMT-11:00) Niue', value: 'Pacific/Niue' },
    { text: '(GMT-11:00) Pago Pago', value: 'Pacific/Pago_Pago' },
    { text: '(GMT-10:00) Hawaii Time', value: 'Pacific/Honolulu' },
    { text: '(GMT-10:00) Rarotonga', value: 'Pacific/Rarotonga' },
    { text: '(GMT-10:00) Tahiti', value: 'Pacific/Tahiti' },
    { text: '(GMT-09:30) Marquesas', value: 'Pacific/Marquesas' },
    { text: '(GMT-09:00) Alaska Time', value: 'America/Anchorage' },
    { text: '(GMT-09:00) Gambier', value: 'Pacific/Gambier' },
    { text: '(GMT-08:00) Pacific Time', value: 'America/Los_Angeles' },
    { text: '(GMT-08:00) Pacific Time - Tijuana', value: 'America/Tijuana' },
    { text: '(GMT-08:00) Pacific Time - Vancouver', value: 'America/Vancouver' },
    { text: '(GMT-08:00) Pacific Time - Whitehorse', value: 'America/Whitehorse' },
    { text: '(GMT-08:00) Pitcairn', value: 'Pacific/Pitcairn' },
    { text: '(GMT-07:00) Mountain Time', value: 'America/Denver' },
    { text: '(GMT-07:00) Mountain Time - Arizona', value: 'America/Phoenix' },
    { text: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan', value: 'America/Mazatlan' },
    { text: '(GMT-07:00) Mountain Time - Dawson Creek', value: 'America/Dawson_Creek' },
    { text: '(GMT-07:00) Mountain Time - Edmonton', value: 'America/Edmonton' },
    { text: '(GMT-07:00) Mountain Time - Hermosillo', value: 'America/Hermosillo' },
    { text: '(GMT-07:00) Mountain Time - Yellowknife', value: 'America/Yellowknife' },
    { text: '(GMT-06:00) Belize', value: 'America/Belize' },
    { text: '(GMT-06:00) Central Time', value: 'America/Chicago' },
    { text: '(GMT-06:00) Central Time - Mexico City', value: 'America/Mexico_City' },
    { text: '(GMT-06:00) Central Time - Regina', value: 'America/Regina' },
    { text: '(GMT-06:00) Central Time - Tegucigalpa', value: 'America/Tegucigalpa' },
    { text: '(GMT-06:00) Central Time - Winnipeg', value: 'America/Winnipeg' },
    { text: '(GMT-06:00) Costa Rica', value: 'America/Costa_Rica' },
    { text: '(GMT-06:00) El Salvador', value: 'America/El_Salvador' },
    { text: '(GMT-06:00) Galapagos', value: 'Pacific/Galapagos' },
    { text: '(GMT-06:00) Guatemala', value: 'America/Guatemala' },
    { text: '(GMT-06:00) Managua', value: 'America/Managua' },
    { text: '(GMT-05:00) America Cancun', value: 'America/Cancun' },
    { text: '(GMT-05:00) Bogota', value: 'America/Bogota' },
    { text: '(GMT-05:00) Easter Island', value: 'Pacific/Easter' },
    { text: '(GMT-05:00) Eastern Time', value: 'America/New_York' },
    { text: '(GMT-05:00) Eastern Time - Iqaluit', value: 'America/Iqaluit' },
    { text: '(GMT-05:00) Eastern Time - Toronto', value: 'America/Toronto' },
    { text: '(GMT-05:00) Guayaquil', value: 'America/Guayaquil' },
    { text: '(GMT-05:00) Havana', value: 'America/Havana' },
    { text: '(GMT-05:00) Jamaica', value: 'America/Jamaica' },
    { text: '(GMT-05:00) Lima', value: 'America/Lima' },
    { text: '(GMT-05:00) Nassau', value: 'America/Nassau' },
    { text: '(GMT-05:00) Panama', value: 'America/Panama' },
    { text: '(GMT-05:00) Port-au-Prince', value: 'America/Port-au-Prince' },
    { text: '(GMT-05:00) Rio Branco', value: 'America/Rio_Branco' },
    { text: '(GMT-04:00) Atlantic Time - Halifax', value: 'America/Halifax' },
    { text: '(GMT-04:00) Barbados', value: 'America/Barbados' },
    { text: '(GMT-04:00) Bermuda', value: 'Atlantic/Bermuda' },
    { text: '(GMT-04:00) Boa Vista', value: 'America/Boa_Vista' },
    { text: '(GMT-04:00) Caracas', value: 'America/Caracas' },
    { text: '(GMT-04:00) Curacao', value: 'America/Curacao' },
    { text: '(GMT-04:00) Grand Turk', value: 'America/Grand_Turk' },
    { text: '(GMT-04:00) Guyana', value: 'America/Guyana' },
    { text: '(GMT-04:00) La Paz', value: 'America/La_Paz' },
    { text: '(GMT-04:00) Manaus', value: 'America/Manaus' },
    { text: '(GMT-04:00) Martinique', value: 'America/Martinique' },
    { text: '(GMT-04:00) Port of Spain', value: 'America/Port_of_Spain' },
    { text: '(GMT-04:00) Porto Velho', value: 'America/Porto_Velho' },
    { text: '(GMT-04:00) Puerto Rico', value: 'America/Puerto_Rico' },
    { text: '(GMT-04:00) Santo Domingo', value: 'America/Santo_Domingo' },
    { text: '(GMT-04:00) Thule', value: 'America/Thule' },
    { text: '(GMT-03:30) Newfoundland Time - St. Johns', value: 'America/St_Johns' },
    { text: '(GMT-03:00) Araguaina', value: 'America/Araguaina' },
    { text: '(GMT-03:00) Asuncion', value: 'America/Asuncion' },
    { text: '(GMT-03:00) Belem', value: 'America/Belem' },
    { text: '(GMT-03:00) Buenos Aires', value: 'America/Argentina/Buenos_Aires' },
    { text: '(GMT-03:00) Campo Grande', value: 'America/Campo_Grande' },
    { text: '(GMT-03:00) Cayenne', value: 'America/Cayenne' },
    { text: '(GMT-03:00) Cuiaba', value: 'America/Cuiaba' },
    { text: '(GMT-03:00) Fortaleza', value: 'America/Fortaleza' },
    { text: '(GMT-03:00) Godthab', value: 'America/Godthab' },
    { text: '(GMT-03:00) Maceio', value: 'America/Maceio' },
    { text: '(GMT-03:00) Miquelon', value: 'America/Miquelon' },
    { text: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
    { text: '(GMT-03:00) Palmer', value: 'Antarctica/Palmer' },
    { text: '(GMT-03:00) Paramaribo', value: 'America/Paramaribo' },
    { text: '(GMT-03:00) Punta Arenas', value: 'America/Punta_Arenas' },
    { text: '(GMT-03:00) Recife', value: 'America/Recife' },
    { text: '(GMT-03:00) Rothera', value: 'Antarctica/Rothera' },
    { text: '(GMT-03:00) Salvador', value: 'America/Bahia' },
    { text: '(GMT-03:00) Santiago', value: 'America/Santiago' },
    { text: '(GMT-03:00) Stanley', value: 'Atlantic/Stanley' },
    { text: '(GMT-02:00) Noronha', value: 'America/Noronha' },
    { text: '(GMT-02:00) Sao Paulo', value: 'America/Sao_Paulo' },
    { text: '(GMT-02:00) South Georgia', value: 'Atlantic/South_Georgia' },
    { text: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
    { text: '(GMT-01:00) Cape Verde', value: 'Atlantic/Cape_Verde' },
    { text: '(GMT-01:00) Scoresbysund', value: 'America/Scoresbysund' },
    { text: '(GMT+00:00) Abidjan', value: 'Africa/Abidjan' },
    { text: '(GMT+00:00) Accra', value: 'Africa/Accra' },
    { text: '(GMT+00:00) Bissau', value: 'Africa/Bissau' },
    { text: '(GMT+00:00) Canary Islands', value: 'Atlantic/Canary' },
    { text: '(GMT+00:00) Casablanca', value: 'Africa/Casablanca' },
    { text: '(GMT+00:00) Danmarkshavn', value: 'America/Danmarkshavn' },
    { text: '(GMT+00:00) Dublin', value: 'Europe/Dublin' },
    { text: '(GMT+00:00) El Aaiun', value: 'Africa/El_Aaiun' },
    { text: '(GMT+00:00) Faeroe', value: 'Atlantic/Faroe' },
    { text: '(GMT+00:00) GMT (no daylight saving)', value: 'Etc/GMT' },
    { text: '(GMT+00:00) Lisbon', value: 'Europe/Lisbon' },
    { text: '(GMT+00:00) London', value: 'Europe/London' },
    { text: '(GMT+00:00) Monrovia', value: 'Africa/Monrovia' },
    { text: '(GMT+00:00) Reykjavik', value: 'Atlantic/Reykjavik' },
    { text: 'UTC', value: 'UTC' },
    { text: '(GMT+01:00) Algiers', value: 'Africa/Algiers' },
    { text: '(GMT+01:00) Amsterdam', value: 'Europe/Amsterdam' },
    { text: '(GMT+01:00) Andorra', value: 'Europe/Andorra' },
    { text: '(GMT+01:00) Berlin', value: 'Europe/Berlin' },
    { text: '(GMT+01:00) Brussels', value: 'Europe/Brussels' },
    { text: '(GMT+01:00) Budapest', value: 'Europe/Budapest' },
    { text: '(GMT+01:00) Central European Time - Belgrade', value: 'Europe/Belgrade' },
    { text: '(GMT+01:00) Central European Time - Prague', value: 'Europe/Prague' },
    { text: '(GMT+01:00) Ceuta', value: 'Africa/Ceuta' },
    { text: '(GMT+01:00) Copenhagen', value: 'Europe/Copenhagen' },
    { text: '(GMT+01:00) Gibraltar', value: 'Europe/Gibraltar' },
    { text: '(GMT+01:00) Lagos', value: 'Africa/Lagos' },
    { text: '(GMT+01:00) Luxembourg', value: 'Europe/Luxembourg' },
    { text: '(GMT+01:00) Madrid', value: 'Europe/Madrid' },
    { text: '(GMT+01:00) Malta', value: 'Europe/Malta' },
    { text: '(GMT+01:00) Monaco', value: 'Europe/Monaco' },
    { text: '(GMT+01:00) Ndjamena', value: 'Africa/Ndjamena' },
    { text: '(GMT+01:00) Oslo', value: 'Europe/Oslo' },
    { text: '(GMT+01:00) Paris', value: 'Europe/Paris' },
    { text: '(GMT+01:00) Rome', value: 'Europe/Rome' },
    { text: '(GMT+01:00) Stockholm', value: 'Europe/Stockholm' },
    { text: '(GMT+01:00) Tirane', value: 'Europe/Tirane' },
    { text: '(GMT+01:00) Tunis', value: 'Africa/Tunis' },
    { text: '(GMT+01:00) Vienna', value: 'Europe/Vienna' },
    { text: '(GMT+01:00) Warsaw', value: 'Europe/Warsaw' },
    { text: '(GMT+01:00) Zurich', value: 'Europe/Zurich' },
    { text: '(GMT+02:00) Amman', value: 'Asia/Amman' },
    { text: '(GMT+02:00) Athens', value: 'Europe/Athens' },
    { text: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
    { text: '(GMT+02:00) Bucharest', value: 'Europe/Bucharest' },
    { text: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
    { text: '(GMT+02:00) Chisinau', value: 'Europe/Chisinau' },
    { text: '(GMT+02:00) Damascus', value: 'Asia/Damascus' },
    { text: '(GMT+02:00) Gaza', value: 'Asia/Gaza' },
    { text: '(GMT+02:00) Helsinki', value: 'Europe/Helsinki' },
    { text: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
    { text: '(GMT+02:00) Johannesburg', value: 'Africa/Johannesburg' },
    { text: '(GMT+02:00) Khartoum', value: 'Africa/Khartoum' },
    { text: '(GMT+02:00) Kiev', value: 'Europe/Kiev' },
    { text: '(GMT+02:00) Maputo', value: 'Africa/Maputo' },
    { text: '(GMT+02:00) Moscow-01 - Kaliningrad', value: 'Europe/Kaliningrad' },
    { text: '(GMT+02:00) Nicosia', value: 'Asia/Nicosia' },
    { text: '(GMT+02:00) Riga', value: 'Europe/Riga' },
    { text: '(GMT+02:00) Sofia', value: 'Europe/Sofia' },
    { text: '(GMT+02:00) Tallinn', value: 'Europe/Tallinn' },
    { text: '(GMT+02:00) Tripoli', value: 'Africa/Tripoli' },
    { text: '(GMT+02:00) Vilnius', value: 'Europe/Vilnius' },
    { text: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
    { text: '(GMT+03:00) Baghdad', value: 'Asia/Baghdad' },
    { text: '(GMT+03:00) Istanbul', value: 'Europe/Istanbul' },
    { text: '(GMT+03:00) Minsk', value: 'Europe/Minsk' },
    { text: '(GMT+03:00) Moscow+00 - Moscow', value: 'Europe/Moscow' },
    { text: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
    { text: '(GMT+03:00) Qatar', value: 'Asia/Qatar' },
    { text: '(GMT+03:00) Riyadh', value: 'Asia/Riyadh' },
    { text: '(GMT+03:00) Syowa', value: 'Antarctica/Syowa' },
    { text: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
    { text: '(GMT+04:00) Baku', value: 'Asia/Baku' },
    { text: '(GMT+04:00) Dubai', value: 'Asia/Dubai' },
    { text: '(GMT+04:00) Mahe', value: 'Indian/Mahe' },
    { text: '(GMT+04:00) Mauritius', value: 'Indian/Mauritius' },
    { text: '(GMT+04:00) Moscow+01 - Samara', value: 'Europe/Samara' },
    { text: '(GMT+04:00) Reunion', value: 'Indian/Reunion' },
    { text: '(GMT+04:00) Tbilisi', value: 'Asia/Tbilisi' },
    { text: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
    { text: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
    { text: '(GMT+05:00) Aqtau', value: 'Asia/Aqtau' },
    { text: '(GMT+05:00) Aqtobe', value: 'Asia/Aqtobe' },
    { text: '(GMT+05:00) Ashgabat', value: 'Asia/Ashgabat' },
    { text: '(GMT+05:00) Dushanbe', value: 'Asia/Dushanbe' },
    { text: '(GMT+05:00) Karachi', value: 'Asia/Karachi' },
    { text: '(GMT+05:00) Kerguelen', value: 'Indian/Kerguelen' },
    { text: '(GMT+05:00) Maldives', value: 'Indian/Maldives' },
    { text: '(GMT+05:00) Mawson', value: 'Antarctica/Mawson' },
    { text: '(GMT+05:00) Moscow+02 - Yekaterinburg', value: 'Asia/Yekaterinburg' },
    { text: '(GMT+05:00) Tashkent', value: 'Asia/Tashkent' },
    { text: '(GMT+05:30) Colombo', value: 'Asia/Colombo' },
    { text: '(GMT+05:30) India Standard Time', value: 'Asia/Calcutta' },
    { text: '(GMT+05:45) Kathmandu', value: 'Asia/Kathmandu' },
    { text: '(GMT+06:00) Almaty', value: 'Asia/Almaty' },
    { text: '(GMT+06:00) Bishkek', value: 'Asia/Bishkek' },
    { text: '(GMT+06:00) Chagos', value: 'Indian/Chagos' },
    { text: '(GMT+06:00) Dhaka', value: 'Asia/Dhaka' },
    { text: '(GMT+06:00) Moscow+03 - Omsk', value: 'Asia/Omsk' },
    { text: '(GMT+06:00) Thimphu', value: 'Asia/Thimphu' },
    { text: '(GMT+06:00) Vostok', value: 'Antarctica/Vostok' },
    { text: '(GMT+06:30) Cocos', value: 'Indian/Cocos' },
    { text: '(GMT+06:30) Rangoon', value: 'Asia/Yangon' },
    { text: '(GMT+07:00) Bangkok', value: 'Asia/Bangkok' },
    { text: '(GMT+07:00) Christmas', value: 'Indian/Christmas' },
    { text: '(GMT+07:00) Davis', value: 'Antarctica/Davis' },
    { text: '(GMT+07:00) Hanoi', value: 'Asia/Saigon' },
    { text: '(GMT+07:00) Hovd', value: 'Asia/Hovd' },
    { text: '(GMT+07:00) Jakarta', value: 'Asia/Jakarta' },
    { text: '(GMT+07:00) Moscow+04 - Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
    { text: '(GMT+08:00) Brunei', value: 'Asia/Brunei' },
    { text: '(GMT+08:00) China Time - Beijing', value: 'Asia/Shanghai' },
    { text: '(GMT+08:00) Choibalsan', value: 'Asia/Choibalsan' },
    { text: '(GMT+08:00) Hong Kong', value: 'Asia/Hong_Kong' },
    { text: '(GMT+08:00) Kuala Lumpur', value: 'Asia/Kuala_Lumpur' },
    { text: '(GMT+08:00) Macau', value: 'Asia/Macau' },
    { text: '(GMT+08:00) Makassar', value: 'Asia/Makassar' },
    { text: '(GMT+08:00) Manila', value: 'Asia/Manila' },
    { text: '(GMT+08:00) Moscow+05 - Irkutsk', value: 'Asia/Irkutsk' },
    { text: '(GMT+08:00) Singapore', value: 'Asia/Singapore' },
    { text: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
    { text: '(GMT+08:00) Ulaanbaatar', value: 'Asia/Ulaanbaatar' },
    { text: '(GMT+08:00) Western Time - Perth', value: 'Australia/Perth' },
    { text: '(GMT+08:30) Pyongyang', value: 'Asia/Pyongyang' },
    { text: '(GMT+09:00) Dili', value: 'Asia/Dili' },
    { text: '(GMT+09:00) Jayapura', value: 'Asia/Jayapura' },
    { text: '(GMT+09:00) Moscow+06 - Yakutsk', value: 'Asia/Yakutsk' },
    { text: '(GMT+09:00) Palau', value: 'Pacific/Palau' },
    { text: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
    { text: '(GMT+09:00) Tokyo', value: 'Asia/Tokyo' },
    { text: '(GMT+09:30) Central Time - Darwin', value: 'Australia/Darwin' },
    { text: "(GMT+10:00) Dumont D'Urville", value: 'Antarctica/DumontDUrville' },
    { text: '(GMT+10:00) Eastern Time - Brisbane', value: 'Australia/Brisbane' },
    { text: '(GMT+10:00) Guam', value: 'Pacific/Guam' },
    { text: '(GMT+10:00) Moscow+07 - Vladivostok', value: 'Asia/Vladivostok' },
    { text: '(GMT+10:00) Port Moresby', value: 'Pacific/Port_Moresby' },
    { text: '(GMT+10:00) Truk', value: 'Pacific/Chuuk' },
    { text: '(GMT+10:30) Central Time - Adelaide', value: 'Australia/Adelaide' },
    { text: '(GMT+11:00) Casey', value: 'Antarctica/Casey' },
    { text: '(GMT+11:00) Eastern Time - Hobart', value: 'Australia/Hobart' },
    { text: '(GMT+11:00) Eastern Time - Melbourne, Sydney', value: 'Australia/Sydney' },
    { text: '(GMT+11:00) Efate', value: 'Pacific/Efate' },
    { text: '(GMT+11:00) Guadalcanal', value: 'Pacific/Guadalcanal' },
    { text: '(GMT+11:00) Kosrae', value: 'Pacific/Kosrae' },
    { text: '(GMT+11:00) Moscow+08 - Magadan', value: 'Asia/Magadan' },
    { text: '(GMT+11:00) Norfolk', value: 'Pacific/Norfolk' },
    { text: '(GMT+11:00) Noumea', value: 'Pacific/Noumea' },
    { text: '(GMT+11:00) Ponape', value: 'Pacific/Pohnpei' },
    { text: '(GMT+12:00) Funafuti', value: 'Pacific/Funafuti' },
    { text: '(GMT+12:00) Kwajalein', value: 'Pacific/Kwajalein' },
    { text: '(GMT+12:00) Majuro', value: 'Pacific/Majuro' },
    { text: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy', value: 'Asia/Kamchatka' },
    { text: '(GMT+12:00) Nauru', value: 'Pacific/Nauru' },
    { text: '(GMT+12:00) Tarawa', value: 'Pacific/Tarawa' },
    { text: '(GMT+12:00) Wake', value: 'Pacific/Wake' },
    { text: '(GMT+12:00) Wallis', value: 'Pacific/Wallis' },
    { text: '(GMT+13:00) Auckland', value: 'Pacific/Auckland' },
    { text: '(GMT+13:00) Enderbury', value: 'Pacific/Enderbury' },
    { text: '(GMT+13:00) Fakaofo', value: 'Pacific/Fakaofo' },
    { text: '(GMT+13:00) Fiji', value: 'Pacific/Fiji' },
    { text: '(GMT+13:00) Tongatapu', value: 'Pacific/Tongatapu' },
    { text: '(GMT+14:00) Apia', value: 'Pacific/Apia' },
    { text: '(GMT+14:00) Kiritimati', value: 'Pacific/Kiritimati' },
]


export const PROVINCES = [
    {
        "id": "4829764",
        "label": "Alabama",
        "value": "Alabama",
        "lat": 32.75041,
        "lon": -86.75026,
        "poi_name": "Alabama",
        "parent_id": "6252001",
        "long": -86.75026,
        "text": "Alabama"
    },
    {
        "id": "5879092",
        "label": "Alaska",
        "value": "Alaska",
        "lat": 64.00028,
        "lon": -150.00028,
        "poi_name": "Alaska",
        "parent_id": "6252001",
        "long": -150.00028,
        "text": "Alaska"
    },
    {
        "id": "5551752",
        "label": "Arizona",
        "value": "Arizona",
        "lat": 34.5003,
        "lon": -111.50098,
        "poi_name": "Arizona",
        "parent_id": "6252001",
        "long": -111.50098,
        "text": "Arizona"
    },
    {
        "id": "4099753",
        "label": "Arkansas",
        "value": "Arkansas",
        "lat": 34.75037,
        "lon": -92.50044,
        "poi_name": "Arkansas",
        "parent_id": "6252001",
        "long": -92.50044,
        "text": "Arkansas"
    },
    {
        "id": "5332921",
        "label": "California",
        "value": "California",
        "lat": 37.25022,
        "lon": -119.75126,
        "poi_name": "California",
        "parent_id": "6252001",
        "long": -119.75126,
        "text": "California"
    },
    {
        "id": "5417618",
        "label": "Colorado",
        "value": "Colorado",
        "lat": 39.00027,
        "lon": -105.50083,
        "poi_name": "Colorado",
        "parent_id": "6252001",
        "long": -105.50083,
        "text": "Colorado"
    },
    {
        "id": "4831725",
        "label": "Connecticut",
        "value": "Connecticut",
        "lat": 41.66704,
        "lon": -72.66648,
        "poi_name": "Connecticut",
        "parent_id": "6252001",
        "long": -72.66648,
        "text": "Connecticut"
    },
    {
        "id": "4142224",
        "label": "Delaware",
        "value": "Delaware",
        "lat": 39.00039,
        "lon": -75.49992,
        "poi_name": "Delaware",
        "parent_id": "6252001",
        "long": -75.49992,
        "text": "Delaware"
    },
    {
        "id": "4138106",
        "label": "District of Columbia",
        "value": "District of Columbia",
        "lat": 38.91706,
        "lon": -77.00025,
        "poi_name": "District of Columbia",
        "parent_id": "6252001",
        "long": -77.00025,
        "text": "District of Columbia"
    },
    {
        "id": "4155751",
        "label": "Florida",
        "value": "Florida",
        "lat": 28.75054,
        "lon": -82.5001,
        "poi_name": "Florida",
        "parent_id": "6252001",
        "long": -82.5001,
        "text": "Florida"
    },
    {
        "id": "4197000",
        "label": "Georgia",
        "value": "Georgia",
        "lat": 32.75042,
        "lon": -83.50018,
        "poi_name": "Georgia",
        "parent_id": "6252001",
        "long": -83.50018,
        "text": "Georgia"
    },
    {
        "id": "5855797",
        "label": "Hawaii",
        "value": "Hawaii",
        "lat": 20.75028,
        "lon": -156.50028,
        "poi_name": "Hawaii",
        "parent_id": "6252001",
        "long": -156.50028,
        "text": "Hawaii"
    },
    {
        "id": "5596512",
        "label": "Idaho",
        "value": "Idaho",
        "lat": 44.5002,
        "lon": -114.25118,
        "poi_name": "Idaho",
        "parent_id": "6252001",
        "long": -114.25118,
        "text": "Idaho"
    },
    {
        "id": "4896861",
        "label": "Illinois",
        "value": "Illinois",
        "lat": 40.00032,
        "lon": -89.25037,
        "poi_name": "Illinois",
        "parent_id": "6252001",
        "long": -89.25037,
        "text": "Illinois"
    },
    {
        "id": "4921868",
        "label": "Indiana",
        "value": "Indiana",
        "lat": 40.00032,
        "lon": -86.25027,
        "poi_name": "Indiana",
        "parent_id": "6252001",
        "long": -86.25027,
        "text": "Indiana"
    },
    {
        "id": "4862182",
        "label": "Iowa",
        "value": "Iowa",
        "lat": 42.00027,
        "lon": -93.50049,
        "poi_name": "Iowa",
        "parent_id": "6252001",
        "long": -93.50049,
        "text": "Iowa"
    },
    {
        "id": "4273857",
        "label": "Kansas",
        "value": "Kansas",
        "lat": 38.50029,
        "lon": -98.50063,
        "poi_name": "Kansas",
        "parent_id": "6252001",
        "long": -98.50063,
        "text": "Kansas"
    },
    {
        "id": "6254925",
        "label": "Kentucky",
        "value": "Kentucky",
        "lat": 38.20042,
        "lon": -84.87762,
        "poi_name": "Kentucky",
        "parent_id": "6252001",
        "long": -84.87762,
        "text": "Kentucky"
    },
    {
        "id": "4331987",
        "label": "Louisiana",
        "value": "Louisiana",
        "lat": 31.00047,
        "lon": -92.0004,
        "poi_name": "Louisiana",
        "parent_id": "6252001",
        "long": -92.0004,
        "text": "Louisiana"
    },
    {
        "id": "4971068",
        "label": "Maine",
        "value": "Maine",
        "lat": 45.50032,
        "lon": -69.24977,
        "poi_name": "Maine",
        "parent_id": "6252001",
        "long": -69.24977,
        "text": "Maine"
    },
    {
        "id": "4361885",
        "label": "Maryland",
        "value": "Maryland",
        "lat": 39.00039,
        "lon": -76.74997,
        "poi_name": "Maryland",
        "parent_id": "6252001",
        "long": -76.74997,
        "text": "Maryland"
    },
    {
        "id": "6254926",
        "label": "Massachusetts",
        "value": "Massachusetts",
        "lat": 42.36565,
        "lon": -71.10832,
        "poi_name": "Massachusetts",
        "parent_id": "6252001",
        "long": -71.10832,
        "text": "Massachusetts"
    },
    {
        "id": "5001836",
        "label": "Michigan",
        "value": "Michigan",
        "lat": 44.25029,
        "lon": -85.50033,
        "poi_name": "Michigan",
        "parent_id": "6252001",
        "long": -85.50033,
        "text": "Michigan"
    },
    {
        "id": "5037779",
        "label": "Minnesota",
        "value": "Minnesota",
        "lat": 46.25024,
        "lon": -94.25055,
        "poi_name": "Minnesota",
        "parent_id": "6252001",
        "long": -94.25055,
        "text": "Minnesota"
    },
    {
        "id": "4436296",
        "label": "Mississippi",
        "value": "Mississippi",
        "lat": 32.75041,
        "lon": -89.75036,
        "poi_name": "Mississippi",
        "parent_id": "6252001",
        "long": -89.75036,
        "text": "Mississippi"
    },
    {
        "id": "4398678",
        "label": "Missouri",
        "value": "Missouri",
        "lat": 38.25031,
        "lon": -92.50046,
        "poi_name": "Missouri",
        "parent_id": "6252001",
        "long": -92.50046,
        "text": "Missouri"
    },
    {
        "id": "5667009",
        "label": "Montana",
        "value": "Montana",
        "lat": 47.00025,
        "lon": -109.75102,
        "poi_name": "Montana",
        "parent_id": "6252001",
        "long": -109.75102,
        "text": "Montana"
    },
    {
        "id": "5073708",
        "label": "Nebraska",
        "value": "Nebraska",
        "lat": 41.50028,
        "lon": -99.75067,
        "poi_name": "Nebraska",
        "parent_id": "6252001",
        "long": -99.75067,
        "text": "Nebraska"
    },
    {
        "id": "5509151",
        "label": "Nevada",
        "value": "Nevada",
        "lat": 39.25021,
        "lon": -116.75119,
        "poi_name": "Nevada",
        "parent_id": "6252001",
        "long": -116.75119,
        "text": "Nevada"
    },
    {
        "id": "5090174",
        "label": "New Hampshire",
        "value": "New Hampshire",
        "lat": 43.66702,
        "lon": -71.4998,
        "poi_name": "New Hampshire",
        "parent_id": "6252001",
        "long": -71.4998,
        "text": "New Hampshire"
    },
    {
        "id": "5101760",
        "label": "New Jersey",
        "value": "New Jersey",
        "lat": 40.16706,
        "lon": -74.49987,
        "poi_name": "New Jersey",
        "parent_id": "6252001",
        "long": -74.49987,
        "text": "New Jersey"
    },
    {
        "id": "5481136",
        "label": "New Mexico",
        "value": "New Mexico",
        "lat": 34.50034,
        "lon": -106.00085,
        "poi_name": "New Mexico",
        "parent_id": "6252001",
        "long": -106.00085,
        "text": "New Mexico"
    },
    {
        "id": "5128638",
        "label": "New York",
        "value": "New York",
        "lat": 43.00035,
        "lon": -75.4999,
        "poi_name": "New York",
        "parent_id": "6252001",
        "long": -75.4999,
        "text": "New York"
    },
    {
        "id": "4482348",
        "label": "North Carolina",
        "value": "North Carolina",
        "lat": 35.50069,
        "lon": -80.00032,
        "poi_name": "North Carolina",
        "parent_id": "6252001",
        "long": -80.00032,
        "text": "North Carolina"
    },
    {
        "id": "5690763",
        "label": "North Dakota",
        "value": "North Dakota",
        "lat": 47.50027,
        "lon": -100.00068,
        "poi_name": "North Dakota",
        "parent_id": "6252001",
        "long": -100.00068,
        "text": "North Dakota"
    },
    {
        "id": "5165418",
        "label": "Ohio",
        "value": "Ohio",
        "lat": 40.25034,
        "lon": -83.00018,
        "poi_name": "Ohio",
        "parent_id": "6252001",
        "long": -83.00018,
        "text": "Ohio"
    },
    {
        "id": "4544379",
        "label": "Oklahoma",
        "value": "Oklahoma",
        "lat": 35.49209,
        "lon": -97.50328,
        "poi_name": "Oklahoma",
        "parent_id": "6252001",
        "long": -97.50328,
        "text": "Oklahoma"
    },
    {
        "id": "5744337",
        "label": "Oregon",
        "value": "Oregon",
        "lat": 44.00013,
        "lon": -120.50139,
        "poi_name": "Oregon",
        "parent_id": "6252001",
        "long": -120.50139,
        "text": "Oregon"
    },
    {
        "id": "6254927",
        "label": "Pennsylvania",
        "value": "Pennsylvania",
        "lat": 40.27245,
        "lon": -76.90567,
        "poi_name": "Pennsylvania",
        "parent_id": "6252001",
        "long": -76.90567,
        "text": "Pennsylvania"
    },
    {
        "id": "5224323",
        "label": "Rhode Island",
        "value": "Rhode Island",
        "lat": 41.75038,
        "lon": -71.49978,
        "poi_name": "Rhode Island",
        "parent_id": "6252001",
        "long": -71.49978,
        "text": "Rhode Island"
    },
    {
        "id": "4597040",
        "label": "South Carolina",
        "value": "South Carolina",
        "lat": 34.00043,
        "lon": -81.00009,
        "poi_name": "South Carolina",
        "parent_id": "6252001",
        "long": -81.00009,
        "text": "South Carolina"
    },
    {
        "id": "5769223",
        "label": "South Dakota",
        "value": "South Dakota",
        "lat": 44.50026,
        "lon": -100.25069,
        "poi_name": "South Dakota",
        "parent_id": "6252001",
        "long": -100.25069,
        "text": "South Dakota"
    },
    {
        "id": "4662168",
        "label": "Tennessee",
        "value": "Tennessee",
        "lat": 35.75035,
        "lon": -86.25027,
        "poi_name": "Tennessee",
        "parent_id": "6252001",
        "long": -86.25027,
        "text": "Tennessee"
    },
    {
        "id": "4736286",
        "label": "Texas",
        "value": "Texas",
        "lat": 31.25044,
        "lon": -99.25061,
        "poi_name": "Texas",
        "parent_id": "6252001",
        "long": -99.25061,
        "text": "Texas"
    },
    {
        "id": "5549030",
        "label": "Utah",
        "value": "Utah",
        "lat": 39.25024,
        "lon": -111.75103,
        "poi_name": "Utah",
        "parent_id": "6252001",
        "long": -111.75103,
        "text": "Utah"
    },
    {
        "id": "5242283",
        "label": "Vermont",
        "value": "Vermont",
        "lat": 44.00034,
        "lon": -72.74983,
        "poi_name": "Vermont",
        "parent_id": "6252001",
        "long": -72.74983,
        "text": "Vermont"
    },
    {
        "id": "6254928",
        "label": "Virginia",
        "value": "Virginia",
        "lat": 37.54812,
        "lon": -77.44675,
        "poi_name": "Virginia",
        "parent_id": "6252001",
        "long": -77.44675,
        "text": "Virginia"
    },
    {
        "id": "5815135",
        "label": "Washington",
        "value": "Washington",
        "lat": 47.50012,
        "lon": -120.50147,
        "poi_name": "Washington",
        "parent_id": "6252001",
        "long": -120.50147,
        "text": "Washington"
    },
    {
        "id": "4826850",
        "label": "West Virginia",
        "value": "West Virginia",
        "lat": 38.50038,
        "lon": -80.50009,
        "poi_name": "West Virginia",
        "parent_id": "6252001",
        "long": -80.50009,
        "text": "West Virginia"
    },
    {
        "id": "5279468",
        "label": "Wisconsin",
        "value": "Wisconsin",
        "lat": 44.50024,
        "lon": -90.00041,
        "poi_name": "Wisconsin",
        "parent_id": "6252001",
        "long": -90.00041,
        "text": "Wisconsin"
    },
    {
        "id": "5843591",
        "label": "Wyoming",
        "value": "Wyoming",
        "lat": 43.00024,
        "lon": -107.5009,
        "poi_name": "Wyoming",
        "parent_id": "6252001",
        "long": -107.5009,
        "text": "Wyoming"
    }
]
