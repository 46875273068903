import React, {Component} from 'react';
import { Grid, Divider } from '@material-ui/core';
import {  List,  Icon} from 'semantic-ui-react'
import moment from 'moment-timezone';
import { inject, observer} from 'mobx-react';
import axios from 'axios';
import { PORTALAPIS, DEFAULT_TIMEZONE } from '../../constants';

// export default class CalendarGrid extends Component {
export default inject('appStore')(observer(class TimeSlotGrid extends Component {

    state = {};

    holdSlot = async (slotTime) => {

        const self = this;
        self.props.toggleLoader();

        const { interval, companyId, calendarId } = this.props;

        const { selectedDate, timezone } = this.props.appStore;
        const start = selectedDate + ' ' + slotTime;
        const selectedDateTime = moment.tz(start, timezone);
        const endTime = moment.tz(start, timezone).add(interval, 'minutes')

        const body = {};

        Object.assign(body, {
            startTime: selectedDateTime,
            endTime: endTime,
            company_id: companyId,
            calendarId,
            reservationName: 'Holding Slot',
            conflictingCalendars: true,
            expireIn: 20
        });

        axios({
            method: 'post',
            url: PORTALAPIS.HOLD_SLOT,
            headers: { 'content-type': 'application/json' },
            data: body,
        })
        .then(resp => {
            self.props.toggleLoader();
            if (resp && resp.status === 200) {
                const respData = resp.data;
                if (respData.hold) {

                    this.props.appStore.showUserForm = true;
                    this.props.appStore.showTimeSlots = false;
                    this.props.appStore.slotTime = slotTime;
                    this.props.appStore.holdId = respData.eventData.id
                    if (this.props.timeSlotSelected) {
                        this.props.timeSlotSelected();
                    }

                    
                } else {
                    self.props.showMessage('Requested time slot has already been booked by another user.' +
                            '\nPlease choose a different time slot');
                    self.handleTimeSlotAlreadyBooked(slotTime);
                }
            } else {

                this.props.appStore.showUserForm = false;
                this.props.appStore.showSuccessResult = true;

            }
        })
        .catch(error => {
            self.props.toggleLoader();
            this.props.appStore.showUserForm = false;
            this.props.appStore.showSuccessResult = true;
        })
    }

    selectedTimeSlot = (slot) => {

        // HERE WE SHOULD HOLD THE SLOT AND HANDLE IF SLOT IS NOT AVAILABLE ANYMORE

        this.holdSlot(slot)

        // this.props.appStore.showUserForm = true;
        // this.props.appStore.showTimeSlots = false;
        // this.props.appStore.slotTime = slot;
        // if (this.props.timeSlotSelected) {
        //     this.props.timeSlotSelected();
        // }

    };

    componentDidMount() {}

    handleTimeSlotAlreadyBooked(slotTime) {
        const self = this;

        const {enable, timezoneAvailableTimeSlots, selectedDate, availableTimeSlots,  month, year, googleTimezones} = self.props.appStore;
        const {calendarId} = this.props;

        const timezone = googleTimezones[calendarId] || DEFAULT_TIMEZONE
        const timeSlots = timezoneAvailableTimeSlots[selectedDate];
        const availableTimeSlotsOfTheDay = timeSlots.filter(timeSlot => timeSlot !== slotTime);

        if (availableTimeSlotsOfTheDay.length <= 0) {
            enable.push(selectedDate)
            // enable.splice( enable.indexOf(selectedDate), 1 ); // Disable the date
        }
        timezoneAvailableTimeSlots[selectedDate] = availableTimeSlotsOfTheDay;

        // Remove time slot from availableTimeSlots saved as default timezone

        try {

            const start = selectedDate + ' ' + slotTime;
            const selectedDateTime = moment.tz(start, timezone);
            const key = selectedDateTime.format()
            const slotToRemove = selectedDateTime.format('HH:mm');

            const filteredAvailableTimeSlots = availableTimeSlots[month + '_' + year][key].filter(slot => slot !== slotToRemove);
            availableTimeSlots[month + '_' + year][key] = filteredAvailableTimeSlots;
        }
        catch (e) {

        }
        ////////////////////////////////////////////////////////////////////
        self.props.appStore.showUserForm = false;
        self.props.appStore.enable = enable;
        self.props.appStore.showTimeSlots = availableTimeSlotsOfTheDay.length > 0;
        self.props.appStore.timezoneAvailableTimeSlots = timezoneAvailableTimeSlots;
        self.props.appStore.availableTimeSlots = availableTimeSlots;
    }

    render() {
        const {
            selectedDate,
            showTimeSlots,
            timezone,
            timezoneLabel,
            timezoneAvailableTimeSlots
        } = this.props.appStore;
        let timeSlots = [];
        if (showTimeSlots) {
            timeSlots = timezoneAvailableTimeSlots[selectedDate] || [];
        }

        const { duration } = this.props

        const {xs, sm} = this.props;

        return (
            <Grid container item
                  xs={xs}
                  sm={sm}
                  alignItems={"center"}
                  direction={"column"}
                  className={"right-grid"}
            >
                <div className={'step-header'}>
                    <Icon className={"back-icon"}
                          onClick={this.props.goToPage.bind(this, 1)}
                          name={"arrow left"} size={"large"}>

                    </Icon>
                    <h4>{moment.tz(selectedDate, timezone).format('dddd, MMMM DD, YYYY')}</h4>
                </div>

                <Divider  className={"divider"}/>
                <h3 style={{marginTop: '0px'}}>What time works best?</h3>
                <p>{'(GMT-08:00) Pacific Time' || timezoneLabel}</p>
                <div className={"center"} style={{width: '100%', margin: '1rem 0rem',  maxWidth: '550px', height: '60vh', overflowY: 'auto'}}>
                    <div>
                        {
                            timeSlots.map((slot, index) => {

                                const selectedDateTime =  moment.tz(
                                    moment.tz(selectedDate, timezone).format('YYYY-MM-DD') +
                                    ' ' + slot, timezone
                                );

                                const slotStart = selectedDateTime.format('hh:mm A');
                                const slotEnd = moment.tz(selectedDateTime.add(duration, 'minutes'), timezone)
                                    .format('hh:mm A');

                                return <div onClick={this.selectedTimeSlot.bind(this, slot)}
                                            className={"time-slot"} key={slot + '_' + index}>
                                    <List.Content>
                                        <List.Header>{slotStart + ' - ' + slotEnd}</List.Header>
                                    </List.Content>
                                </div>
                            })
                        }
                    </div>
                </div>
            </Grid>
        );
    }
}));
// }



