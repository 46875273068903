import React, {Component} from 'react';
import { inject, observer} from 'mobx-react';
import MySnackbarContentWrapper from "../snackbars/Snackbar";
import Snackbar from "@material-ui/core/Snackbar";

// export default class CalendarGrid extends Component {
export default inject('appStore')(observer(class Toaster extends Component {

    render() {

        const open = this.props.appStore.open;
        const message = this.props.appStore.message;
        const variant = this.props.appStore.variant;
        return (

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={this.props.handleClose}
            >
                <MySnackbarContentWrapper
                    variant={variant || "error"}
                    message={message}
                    onClose={this.props.handleClose}
                />
            </Snackbar>
        );
    }
}));
// }



